import { Avatar, Button, Stack, Typography } from '@mui/material';
import { Collection, CollectionTwitterUser } from 'common/types/Extension/Collection';
import { CopyAllOutlined, ListOutlined } from '@mui/icons-material';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SearchBar, SearchBarMenu } from 'pages/InfluencerMatcher/Search/SearchBar';

import { AddUserToCollectionDialog } from 'components/InfluencerMatcher/collection/AddUserToCollectionDialog';
import { CopyUserToCollectionDialog } from 'components/InfluencerMatcher/collection/CopyUserToCollectionDialog';
import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { ExtensionDrawer } from 'pages/InfluencerMatcher/Drawer/ExtensionDrawer';
import { InfluencerSearchParams } from 'common/types/Extension/InfluencerFilter';
import { LabelChip } from 'components/chip/LabelChip';
import { TwitterUserList } from 'pages/InfluencerMatcher/Search/TwitterUserList';
import { TwitterUserSearchResult } from 'common/types/Extension/TwitterUserSearchResult';
import { Uris } from 'Uris';
import classes from './CollectionViewDesktop.module.scss';
import { redirectToExternalPage } from 'common/utils';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router-dom';
import { useObservable } from 'react-use';
import { useTracking } from 'common/hooks/useTracking';
import { useUserProfile } from 'common/hooks/useUserProfile';

const DefaultParams: InfluencerSearchParams = {
  keyword_search: {
    type: SearchBarMenu.USER_INFO,
    keyword: '',
  },
};

interface CollectionViewDesktopProps {
  collection: Collection;
}

export const CollectionViewDesktop: FC<CollectionViewDesktopProps> = ({ collection }) => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const { collections$, refetchCollections$ } = useContext(ExtensionContext);
  const collections = useObservable(collections$);

  const [tmpSearchParams, setTmpSearchParams] = useState<InfluencerSearchParams>(DefaultParams);
  const [finalSearchParams, setFinalSearchParams] = useState<InfluencerSearchParams>(DefaultParams);
  const [twitterUsers, setTwitterUsers] = useState<TwitterUserSearchResult[]>([]);
  const [copiedCollection, setCopiedCollection] = useState<Collection | undefined>(undefined);
  const [addTwitterUsers, setAddTwitterUsers] = useState<TwitterUserSearchResult[]>([]);
  const [twitterUserId, setTwitterUserId] = useState<string | undefined>(undefined);

  const [addedTwitterUsers, setAddedTwitterUsers] = useState<TwitterUserSearchResult[]>([]);

  const { value: user } = useUserProfile();

  const onCopyToClicked = useCallback(() => {
    if (!user) {
      redirectToExternalPage(`${Uris.External.Login}?entry_point=console`);
      return;
    }
    setCopiedCollection(collection);
  }, [user, collection]);

  const onMyListClicked = useCallback(() => {
    if (!user) {
      redirectToExternalPage(`${Uris.External.Login}?entry_point=console`);
      return;
    }
    window.open(`${window.location.origin}${Uris.Pages.InfluencerMatcher.Index}?tab=collections`);
  }, [user]);

  const getCollectionTwitterUsers = useCallback(
    async (collectionTwitterUsers: CollectionTwitterUser[]) => {
      await ExtensionAPI.searchTwitterUser(
        { user_ids: collectionTwitterUsers.map((user) => user.id) },
        { page: 0, rowsPerPage: collectionTwitterUsers.length },
      )
        .then(({ status, data }) => {
          if (status === 'success') {
            if (!data?.length) return;
            const noDataUsers = collectionTwitterUsers.filter((collectionUser) =>
              data.every((user) => collectionUser.id !== user.id),
            );
            setTwitterUsers([...data, ...noDataUsers]);
            return;
          }
          showMessage(`Get collection twitter users failed: ${status}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    },
    [showMessage],
  );

  useEffect(() => {
    getCollectionTwitterUsers(collection.twitter_users);
  }, [collection.twitter_users, getCollectionTwitterUsers]);

  const filteredTwitterUsers = useMemo(() => {
    if (!finalSearchParams.keyword_search?.keyword) return twitterUsers;
    const keyword = finalSearchParams.keyword_search.keyword.toLowerCase();
    return twitterUsers.filter(
      (user) => user.username?.toLowerCase().includes(keyword) || user.display_name?.toLowerCase().includes(keyword),
    );
  }, [finalSearchParams, twitterUsers]);

  return (
    <Stack className={classes.root} spacing={8} alignItems='center'>
      <Stack className={classes.top}>
        <Stack className={classes.content} alignItems='center' textAlign='center' spacing={1.5}>
          <LabelChip color='white'>Influencers Matcher</LabelChip>
          <Typography>Discover top Web3 influencers in the world with powerful search tools.</Typography>
          <Typography variant='h2'>{collection.name}</Typography>
          <Stack direction='row' spacing={1.5} alignItems='center'>
            <Typography variant='label1'>by</Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Avatar alt='Avatar' src={collection.author?.image_url || Uris.Public.Image.Header.Avatar} />
              <Stack>
                <Typography variant='label1'>{collection.author?.name}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={classes.bottom} spacing={2}>
        <Stack direction='row-reverse' spacing={1}>
          <Button
            id='btn-table-copy-influencer-to-list'
            variant='contained'
            startIcon={<CopyAllOutlined />}
            disabled={!twitterUsers.length}
            onClick={onCopyToClicked}
          >
            Copy to
          </Button>
          <Button startIcon={<ListOutlined />} variant='outlined' onClick={onMyListClicked}>
            My List
          </Button>
        </Stack>
        <SearchBar
          menus={[SearchBarMenu.USER_INFO]}
          data={tmpSearchParams.keyword_search}
          onSearchClicked={() => setFinalSearchParams(tmpSearchParams)}
          onChange={(data) => setTmpSearchParams((old) => ({ ...old, keyword_search: data }))}
        />
        <TwitterUserList
          twitterUsers={filteredTwitterUsers}
          addedTwitterUsers={addedTwitterUsers}
          onAddTwitterUsersToList={(twitterUsers) => {
            setAddTwitterUsers(twitterUsers);
          }}
          onTwitterUserViewClicked={(twitterUser) => {
            track('click', {
              sub_event: 'influencer_clicked',
              custom_props: {
                entry_point: 'List page',
                influencer_id: twitterUser.id,
                influencer_username: twitterUser.username,
              },
            });
            setTwitterUserId(twitterUser.id);
          }}
        />
      </Stack>

      {addTwitterUsers.length ? (
        <AddUserToCollectionDialog
          source='List table'
          twitterUsers={addTwitterUsers}
          collections={collections || []}
          onSuccess={(collectionId) => {
            setAddedTwitterUsers((old) => {
              const filteredTwitterUsers = addTwitterUsers.filter(
                (addTwitterUser) => old.findIndex((user) => user.id === addTwitterUser.id) === -1,
              );
              return [...old, ...filteredTwitterUsers];
            });
            setAddTwitterUsers([]);
            refetchCollections$.next(true);
            if (typeof collectionId === 'number')
              navigate({ pathname: `${Uris.Pages.InfluencerMatcher.Index}`, search: 'tab=collections' });
          }}
          onCancel={() => {
            setAddTwitterUsers([]);
          }}
        />
      ) : null}

      {copiedCollection ? (
        <CopyUserToCollectionDialog
          source='List table'
          collection={copiedCollection}
          collections={collections || []}
          onSuccess={(collectionId) => {
            setCopiedCollection(undefined);
            refetchCollections$.next(true);
            if (typeof collectionId === 'number')
              navigate({ pathname: `${Uris.Pages.InfluencerMatcher.Index}`, search: 'tab=collections' });
          }}
          onCancel={() => {
            setCopiedCollection(undefined);
          }}
        />
      ) : null}

      {typeof twitterUserId === 'string' ? (
        <ExtensionDrawer
          twitterUserId={twitterUserId}
          onClose={() => {
            setTwitterUserId(undefined);
          }}
        />
      ) : null}
    </Stack>
  );
};
