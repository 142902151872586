import { Button, Stack, Typography } from '@mui/material';
import { CampaignLink, CampaignLinkBase } from 'common/types/CampaignLink';
import { FC, useCallback, useEffect, useState } from 'react';

import { AddOutlined } from '@mui/icons-material';
import { CampaignLinkDeleteDialog } from 'components/CampaignLink/dialog/CampaignLinkDeleteDialog';
import { CampaignLinkEditDialog } from 'components/CampaignLink/dialog/CampaignLinkEditDialog';
import { CampaignLinkList } from 'components/CampaignLink/CampaignLinkList';
import ClientAPI from 'common/ClientAPI';
import { Uris } from 'Uris';
import classes from './UserCampaignLink.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router-dom';

export const UserCampaignLink: FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();

  const [refetch, setRefetch] = useState<boolean>(false);
  const [selectedCampaignLink, setSelectedCampaignLink] = useState<CampaignLink | undefined>(undefined);
  const [editDialogOpened, setEditDialogOpened] = useState<boolean>(false);
  const [duplicateDialogOpened, setDuplicateDialogOpened] = useState<boolean>(false);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState<boolean>(false);

  const {
    loading,
    error,
    value: campaignLinks,
  } = useAsync(async () => {
    return (await ClientAPI.getCampaignLinks()).data;
  }, [refetch]);

  const onCampaignLinkSaved = useCallback(
    async (mode: 'create' | 'modify', campaignLink: CampaignLinkBase) => {
      if (!selectedCampaignLink) return;
      switch (mode) {
        case 'create':
          await ClientAPI.createCampaignLinks([campaignLink])
            .then(({ status, data, message }) => {
              if (status === 'success' && data) {
                setSelectedCampaignLink(undefined);
                setDuplicateDialogOpened(false);
                setRefetch((old) => !old);
                return;
              }
              showMessage(`Create campaign link failed, ${message}`);
            })
            .catch((error) => {
              showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
            });
          break;
        case 'modify':
          await ClientAPI.updateCampaignLink(selectedCampaignLink.id, campaignLink)
            .then(({ status, data, message }) => {
              if (status === 'success' && data) {
                setSelectedCampaignLink(undefined);
                setDuplicateDialogOpened(false);
                setRefetch((old) => !old);
                return;
              }
              showMessage(`Update campaign link failed, ${message}`);
            })
            .catch((error) => {
              showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
            });
          break;
      }
    },
    [selectedCampaignLink, showMessage],
  );

  useEffect(() => {
    error && error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  return (
    <Stack className={classes.root} spacing={4}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h3'>Campaign Link List</Typography>
        <Button
          variant='contained'
          startIcon={<AddOutlined />}
          onClick={() => navigate(Uris.Pages.User.CampaignLink.Create)}
        >
          Create Link
        </Button>
      </Stack>
      <CampaignLinkList
        loading={loading}
        campaignLinks={campaignLinks || []}
        onLinkViewClicked={(campaignLink) => {
          setSelectedCampaignLink(campaignLink);
          setEditDialogOpened(true);
        }}
        onLinkDuplicateClicked={(campaignLink) => {
          setSelectedCampaignLink(campaignLink);
          setDuplicateDialogOpened(true);
        }}
        onLinkDeleteClicked={(campaignLink) => {
          setSelectedCampaignLink(campaignLink);
          setDeleteDialogOpened(true);
        }}
      />
      {editDialogOpened && selectedCampaignLink ? (
        <CampaignLinkEditDialog
          open
          mode='modify'
          campaignLink={selectedCampaignLink}
          onSave={onCampaignLinkSaved}
          onCancel={() => setEditDialogOpened(false)}
        />
      ) : null}
      {duplicateDialogOpened && selectedCampaignLink ? (
        <CampaignLinkEditDialog
          open
          mode='create'
          campaignLink={selectedCampaignLink}
          onSave={onCampaignLinkSaved}
          onCancel={() => setDuplicateDialogOpened(false)}
        />
      ) : null}
      {deleteDialogOpened && selectedCampaignLink ? (
        <CampaignLinkDeleteDialog
          open
          campaignLink={selectedCampaignLink}
          onDelete={() => {
            setSelectedCampaignLink(undefined);
            setDeleteDialogOpened(false);
            setRefetch((old) => !old);
          }}
          onCancel={() => setDeleteDialogOpened(false)}
        />
      ) : null}
    </Stack>
  );
};
