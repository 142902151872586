import { Breadcrumbs, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import {
  CampaignLink,
  CampaignLinkBase,
  CampaignLinkChannel,
  DefaultCampaignLinkBase,
} from 'common/types/CampaignLink';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiberManualRecord, NavigateNextOutlined } from '@mui/icons-material';

import ClientAPI from 'common/ClientAPI';
import { CustomizedCampaignInfo } from 'components/CampaignLink/edit/CustomizedCampaignInfo';
import { CustomizedGeneratedLink } from 'components/CampaignLink/edit/CustomizedGeneratedLink';
import { SelectChannel } from 'components/CampaignLink/edit/SelectChannel';
import { Spinner } from 'components/common/Spinner';
import { TwitterAdsCampaignInfo } from 'components/CampaignLink/edit/TwitterAdsCampaignInfo';
import { TwitterAdsGeneratedLink } from 'components/CampaignLink/edit/TwitterAdsGeneratedLink';
import { Uris } from 'Uris';
import classes from './CreateUserCampaignLink.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router-dom';

const tabs: string[] = ['Channels', 'Campaign Information', 'Generated Link'];

enum CreateUserCampaignLinkStep {
  SELECT_CHANNEL,
  CAMPAIGN_INFO,
  VIEW_GENERATED_LINK,
}

export const CreateUserCampaignLink: FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const [tab, setTab] = useState<CreateUserCampaignLinkStep>(CreateUserCampaignLinkStep.SELECT_CHANNEL);
  const [editCampaignLink, setEditCampaignLink] = useState<CampaignLinkBase>(DefaultCampaignLinkBase);
  const [creating, setCreating] = useState<boolean>(false);
  const [generatedLinks, setGenerateLinks] = useState<CampaignLink[]>([]);

  const {
    loading: audienceLoading,
    error: audienceError,
    value: audiences,
  } = useAsync(async () => {
    return (await ClientAPI.getAudiences()).data;
  }, []);

  const {
    loading: twitterAdGroupPublishRecordLoading,
    error: twitterAdGroupPublishRecordError,
    value: twitterAdGroupPublishRecords,
  } = useAsync(async () => {
    return (await ClientAPI.getTwitterAdGroupPublishRecords()).data;
  }, []);

  useEffect(() => {
    audienceError && audienceError && showMessage(audienceError?.message || 'Unknown Error', 'error');
  }, [audienceError, showMessage]);

  useEffect(() => {
    twitterAdGroupPublishRecordError &&
      twitterAdGroupPublishRecordError &&
      showMessage(twitterAdGroupPublishRecordError?.message || 'Unknown Error', 'error');
  }, [twitterAdGroupPublishRecordError, showMessage]);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  }, []);

  const createCampaignLinks = useCallback(
    async (campaignLinks: CampaignLinkBase[]) => {
      setCreating(true);
      await ClientAPI.createCampaignLinks(campaignLinks)
        .then(({ status, data, message }) => {
          if (status === 'success' && data?.length) {
            setGenerateLinks(data);
            setTab(CreateUserCampaignLinkStep.VIEW_GENERATED_LINK);
            return;
          }
          showMessage(`Create campaign links failed: ${message}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        })
        .finally(() => {
          setCreating(false);
        });
    },
    [showMessage],
  );

  const mainContent = useMemo(() => {
    if (audienceLoading || twitterAdGroupPublishRecordLoading) return <Spinner />;
    switch (tab) {
      case 0: // Select Channel
        return (
          <SelectChannel
            channel={editCampaignLink.channel}
            onNext={(channel: CampaignLinkChannel) => {
              setEditCampaignLink((old) => ({ ...old, channel }));
              setTab(CreateUserCampaignLinkStep.CAMPAIGN_INFO);
            }}
            onCancel={() => navigate(-1)}
          />
        );
      case 1: // Campaign Information
        if (editCampaignLink.channel === CampaignLinkChannel.TWITTER_ADS)
          return (
            <TwitterAdsCampaignInfo
              creating={creating}
              campaignLink={editCampaignLink}
              twitterAdGroupPublishRecords={twitterAdGroupPublishRecords || []}
              audiences={audiences || []}
              onCreate={(campaignLinks) => createCampaignLinks(campaignLinks)}
              onBack={() => setTab(CreateUserCampaignLinkStep.SELECT_CHANNEL)}
            />
          );
        else
          return (
            <CustomizedCampaignInfo
              creating={creating}
              campaignLink={editCampaignLink}
              onCreate={(campaignLink) => createCampaignLinks([campaignLink])}
              onBack={() => setTab(CreateUserCampaignLinkStep.SELECT_CHANNEL)}
            ></CustomizedCampaignInfo>
          );
      case 2: // Generated Link
        if (editCampaignLink.channel === CampaignLinkChannel.TWITTER_ADS)
          return (
            <TwitterAdsGeneratedLink
              campaignLinks={generatedLinks}
              onComplete={() => navigate(Uris.Pages.User.CampaignLink.Index)}
              onBack={() => setTab(CreateUserCampaignLinkStep.CAMPAIGN_INFO)}
            ></TwitterAdsGeneratedLink>
          );
        else
          return (
            <CustomizedGeneratedLink
              campaignLinks={generatedLinks}
              onComplete={() => navigate(Uris.Pages.User.CampaignLink.Index)}
              onBack={() => setTab(CreateUserCampaignLinkStep.CAMPAIGN_INFO)}
            ></CustomizedGeneratedLink>
          );
      default:
        return null;
    }
  }, [
    tab,
    creating,
    editCampaignLink,
    generatedLinks,
    audienceLoading,
    twitterAdGroupPublishRecordLoading,
    audiences,
    twitterAdGroupPublishRecords,
    createCampaignLinks,
    navigate,
  ]);

  return (
    <Stack className={classes.root} spacing={4}>
      <Breadcrumbs separator={<NavigateNextOutlined />} aria-label='breadcrumb'>
        <Link
          variant='subtitle2'
          underline='none'
          className={classes.link}
          onClick={() => navigate(Uris.Pages.User.CampaignLink.Index)}
        >
          Campaign Link
        </Link>
        <Typography variant='subtitle2'>Create</Typography>
      </Breadcrumbs>

      <Stack direction='column' spacing={4} className={classes.form}>
        <Tabs value={tab} onChange={handleTabChange} centered className={classes.tabs}>
          {tabs.map((t, idx) => (
            <Tab
              key={t}
              className={classes.tab}
              disabled={tab < idx || tab === tabs.length - 1}
              label={
                <Stack direction='row' alignItems='center' spacing={1}>
                  <FiberManualRecord />
                  <Typography variant='subtitle2'>{t}</Typography>
                </Stack>
              }
            />
          ))}
        </Tabs>
        {mainContent}
      </Stack>
    </Stack>
  );
};
