import { Button, Stack, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { colorNeutralVariant50, colorPrimary40, colorSurface1 } from 'common/params';

import { ArrowBackOutlined } from '@mui/icons-material';
import { CampaignMessagesDetail } from './DetailTab/CampaignMessagesDetail';
import { CampaignThreadsDetail } from './DetailTab/CampaignThreadsDetail';
import ExtensionAPI from 'common/ExtensionAPI';
import { InfluencerThread } from 'common/types/Extension/InfluencerCampaign';
import { Spinner } from 'components/common/Spinner';
import classes from './InfluencerCampaignDetail.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

export enum InfluencerCampaignDetailMode {
  REPORT = 'Report',
  INFLUENCERS = 'Influencers',
  MESSAGES = 'Messages',
}

interface InfluencerCampaignDetailProps {
  defaultMode?: InfluencerCampaignDetailMode;
  campaignId: number;
  onBackClicked?: () => void;
}

export const InfluencerCampaignDetail: FC<InfluencerCampaignDetailProps> = ({
  defaultMode,
  campaignId,
  onBackClicked,
}) => {
  const { showMessage } = useMessage();
  const [selectedMode, setSelectedMode] = useState<InfluencerCampaignDetailMode>(
    defaultMode || InfluencerCampaignDetailMode.INFLUENCERS,
  );
  const [refetch, setRefetch] = useState<boolean>(false);

  const { loading, value: influencerCampaign } = useAsync(async () => {
    return (await ExtensionAPI.getInfluencerCampaign(campaignId)).data;
  }, [refetch]);

  const updateInfluencerThreads = useCallback(
    async (threads: InfluencerThread[]) => {
      if (!influencerCampaign) return;
      await ExtensionAPI.updateInfluencerCampaign({ ...influencerCampaign, threads: threads })
        .then(({ status, data }) => {
          if (status === 'success' && data) {
            setRefetch((old) => !old);
            return;
          }
          showMessage(`Update campaign failed: ${status}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    },
    [influencerCampaign, showMessage],
  );

  const content = useMemo(() => {
    if (!influencerCampaign) return <Stack className={classes.block}></Stack>;
    switch (selectedMode) {
      case InfluencerCampaignDetailMode.REPORT:
        return <Typography>Comming Soon</Typography>;
      case InfluencerCampaignDetailMode.INFLUENCERS:
        return (
          <CampaignThreadsDetail
            campaign={influencerCampaign}
            onInfluencerThreadsStageChangeClicked={updateInfluencerThreads}
            onInfluencerThreadsStatusChangeClicked={updateInfluencerThreads}
          />
        );
      case InfluencerCampaignDetailMode.MESSAGES:
        return <CampaignMessagesDetail campaign={influencerCampaign} />;
    }
  }, [selectedMode, influencerCampaign, updateInfluencerThreads]);

  if (loading) return <Spinner />;

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack direction='row'>
        <Button startIcon={<ArrowBackOutlined />} onClick={onBackClicked}>
          Back to list
        </Button>
      </Stack>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h3'>{influencerCampaign?.name}</Typography>
        <Stack direction='row' className={classes.mode}>
          {Object.values(InfluencerCampaignDetailMode).map((mode, idx) => (
            <Button
              key={mode}
              variant={selectedMode === mode ? 'contained' : 'outlined'}
              className={
                idx === 0
                  ? classes.left
                  : idx === Object.values(InfluencerCampaignDetailMode).length - 1
                  ? classes.right
                  : classes.mid
              }
              sx={{
                '&.MuiButton-contained': {
                  backgroundColor: colorSurface1,
                  color: colorPrimary40,
                  border: `1px solid ${colorNeutralVariant50}`,
                },
              }}
              onClick={() => setSelectedMode(mode)}
            >
              {mode}
            </Button>
          ))}
        </Stack>
      </Stack>
      {content}
    </Stack>
  );
};
