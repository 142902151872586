import { InfluencerSearchParams, PercentileLevel } from 'common/types/Extension/InfluencerFilter';
import { MetricsRange, TwitterUserSearchResult } from 'common/types/Extension/TwitterUserSearchResult';

import { dedupArray } from 'common/utils';

export const getFilteredTwitterUsers = (
  twitterUsers: TwitterUserSearchResult[],
  searchParams: InfluencerSearchParams,
) => {
  let filteredTwitterUsers = twitterUsers;
  if (searchParams.keyword_search?.keyword) {
    // has keyword -> apply keyword filter
    const keyword = searchParams.keyword_search.keyword.toLowerCase();
    filteredTwitterUsers = filteredTwitterUsers.filter(
      (user) => user.username?.toLowerCase().includes(keyword) || user.display_name?.toLowerCase().includes(keyword),
    );
  }
  if (typeof searchParams.is_project === 'boolean') {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => user.is_project === searchParams.is_project);
  }
  if (searchParams.influence_score) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => {
      let keep = true;
      if (typeof searchParams.influence_score?.max === 'number') {
        keep =
          keep && typeof user.influence_score === 'number' && user.influence_score <= searchParams.influence_score.max;
      }
      if (typeof searchParams.influence_score?.min === 'number') {
        keep =
          keep && typeof user.influence_score === 'number' && user.influence_score >= searchParams.influence_score.min;
      }
      if (searchParams.influence_score?.percentiles?.length) {
        let percentileKeep = false;
        searchParams.influence_score?.percentiles.forEach((percentile) => {
          if (typeof user.influence_percentile !== 'number') return;
          switch (percentile) {
            case PercentileLevel.TOP:
              percentileKeep = percentileKeep || user.influence_percentile >= 0.7;
              break;
            case PercentileLevel.MIDDLE:
              percentileKeep = percentileKeep || (user.influence_percentile < 0.7 && user.influence_percentile > 0.3);
              break;
            case PercentileLevel.BOTTOM:
              percentileKeep = percentileKeep || user.influence_percentile <= 0.3;
              break;
          }
        });
        keep = keep && percentileKeep;
      }
      return keep;
    });
  }
  if (searchParams.followers) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => {
      let keep = true;
      if (typeof searchParams.followers?.max === 'number') {
        keep = keep && typeof user.follower_count === 'number' && user.follower_count <= searchParams.followers.max;
      }
      if (typeof searchParams.followers?.min === 'number') {
        keep = keep && typeof user.follower_count === 'number' && user.follower_count >= searchParams.followers.min;
      }
      return keep;
    });
  }
  if (searchParams.verified_only) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => user.verified);
  }
  if (searchParams.engagement_rate) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => {
      let keep = true;
      if (typeof searchParams.engagement_rate?.max === 'number') {
        keep = keep && typeof user.etr === 'number' && user.etr <= searchParams.engagement_rate.max;
      }
      if (typeof searchParams.engagement_rate?.min === 'number') {
        keep = keep && typeof user.etr === 'number' && user.etr >= searchParams.engagement_rate.min;
      }
      if (searchParams.engagement_rate?.percentiles?.length) {
        let percentileKeep = false;
        searchParams.engagement_rate?.percentiles.forEach((percentile) => {
          if (typeof user.etr_tier !== 'number') return;
          switch (percentile) {
            case PercentileLevel.TOP:
              percentileKeep = percentileKeep || user.etr_tier >= 0.7;
              break;
            case PercentileLevel.MIDDLE:
              percentileKeep = percentileKeep || (user.etr_tier < 0.7 && user.etr_tier > 0.3);
              break;
            case PercentileLevel.BOTTOM:
              percentileKeep = percentileKeep || user.etr_tier <= 0.3;
              break;
          }
        });
        keep = keep && percentileKeep;
      }
      return keep;
    });
  }
  if (searchParams.view_through_rate) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => {
      let keep = true;
      if (typeof searchParams.view_through_rate?.max === 'number') {
        keep = keep && typeof user.vtr === 'number' && user.vtr <= searchParams.view_through_rate.max;
      }
      if (typeof searchParams.view_through_rate?.min === 'number') {
        keep = keep && typeof user.vtr === 'number' && user.vtr >= searchParams.view_through_rate.min;
      }
      if (searchParams.view_through_rate?.percentiles?.length) {
        let percentileKeep = false;
        searchParams.view_through_rate?.percentiles.forEach((percentile) => {
          if (typeof user.vtr_tier !== 'number') return;
          switch (percentile) {
            case PercentileLevel.TOP:
              percentileKeep = percentileKeep || user.vtr_tier >= 0.7;
              break;
            case PercentileLevel.MIDDLE:
              percentileKeep = percentileKeep || (user.vtr_tier < 0.7 && user.vtr_tier > 0.3);
              break;
            case PercentileLevel.BOTTOM:
              percentileKeep = percentileKeep || user.vtr_tier <= 0.3;
              break;
          }
        });
        keep = keep && percentileKeep;
      }
      return keep;
    });
  }
  if (searchParams.campaign_term_ratio) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => {
      let keep = true;
      if (typeof searchParams.campaign_term_ratio?.max === 'number') {
        keep =
          keep &&
          typeof user.campaign_term_ratio === 'number' &&
          user.campaign_term_ratio <= searchParams.campaign_term_ratio.max;
      }
      if (typeof searchParams.campaign_term_ratio?.min === 'number') {
        keep =
          keep &&
          typeof user.campaign_term_ratio === 'number' &&
          user.campaign_term_ratio >= searchParams.campaign_term_ratio.min;
      }
      if (searchParams.campaign_term_ratio?.percentiles?.length) {
        let percentileKeep = false;
        searchParams.campaign_term_ratio?.percentiles.forEach((percentile) => {
          if (typeof user.campaign_term_ratio_tier !== 'number') return;
          switch (percentile) {
            case PercentileLevel.TOP:
              percentileKeep = percentileKeep || user.campaign_term_ratio_tier >= 0.7;
              break;
            case PercentileLevel.MIDDLE:
              percentileKeep =
                percentileKeep || (user.campaign_term_ratio_tier < 0.7 && user.campaign_term_ratio_tier > 0.3);
              break;
            case PercentileLevel.BOTTOM:
              percentileKeep = percentileKeep || user.campaign_term_ratio_tier <= 0.3;
              break;
          }
        });
        keep = keep && percentileKeep;
      }
      return keep;
    });
  }
  if (searchParams.original_tweets_ratio) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => {
      let keep = true;
      if (typeof searchParams.original_tweets_ratio?.max === 'number') {
        keep =
          keep &&
          typeof user.original_tweets_ratio === 'number' &&
          user.original_tweets_ratio <= searchParams.original_tweets_ratio.max;
      }
      if (typeof searchParams.original_tweets_ratio?.min === 'number') {
        keep =
          keep &&
          typeof user.original_tweets_ratio === 'number' &&
          user.original_tweets_ratio >= searchParams.original_tweets_ratio.min;
      }
      return keep;
    });
  }
  if (searchParams.average_tweet_impression) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => {
      let keep = true;
      if (typeof searchParams.average_tweet_impression?.max === 'number') {
        keep =
          keep &&
          typeof user.avg_tweet_impr === 'number' &&
          user.avg_tweet_impr <= searchParams.average_tweet_impression.max;
      }
      if (typeof searchParams.average_tweet_impression?.min === 'number') {
        keep =
          keep &&
          typeof user.avg_tweet_impr === 'number' &&
          user.avg_tweet_impr >= searchParams.average_tweet_impression.min;
      }
      if (searchParams.average_tweet_impression?.percentiles?.length) {
        let percentileKeep = false;
        searchParams.average_tweet_impression?.percentiles.forEach((percentile) => {
          if (typeof user.avg_tweet_impr_tier !== 'number') return;
          switch (percentile) {
            case PercentileLevel.TOP:
              percentileKeep = percentileKeep || user.avg_tweet_impr_tier >= 0.7;
              break;
            case PercentileLevel.MIDDLE:
              percentileKeep = percentileKeep || (user.avg_tweet_impr_tier < 0.7 && user.avg_tweet_impr_tier > 0.3);
              break;
            case PercentileLevel.BOTTOM:
              percentileKeep = percentileKeep || user.avg_tweet_impr_tier <= 0.3;
              break;
          }
        });
        keep = keep && percentileKeep;
      }
      return keep;
    });
  }
  if (searchParams.categories?.length) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => {
      return (searchParams.categories || []).some((category) => (user.categories || []).some((c) => c === category));
    });
  }
  if (searchParams.languages?.length) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => {
      return (searchParams.languages || []).some((language) => (user.languages || []).some((l) => l === language));
    });
  }
  if (searchParams.locations?.length) {
    filteredTwitterUsers = filteredTwitterUsers.filter((user) => {
      return (searchParams.locations || []).some((location) => user.location === location);
    });
  }
  return filteredTwitterUsers;
};

export const getTwitterUsersMetricsRange = (twitterUsers: TwitterUserSearchResult[]) => {
  const metricsRange: MetricsRange = {
    followers_min: Math.min(
      ...twitterUsers.filter((user) => typeof user.follower_count === 'number').map((user) => user.follower_count!),
    ),
    followers_max: Math.max(
      ...twitterUsers.filter((user) => typeof user.follower_count === 'number').map((user) => user.follower_count!),
    ),
    etr_min: Math.min(...twitterUsers.filter((user) => typeof user.etr === 'number').map((user) => user.etr!)),
    etr_max: Math.max(...twitterUsers.filter((user) => typeof user.etr === 'number').map((user) => user.etr!)),
    vtr_min: Math.min(...twitterUsers.filter((user) => typeof user.vtr === 'number').map((user) => user.vtr!)),
    vtr_max: Math.max(...twitterUsers.filter((user) => typeof user.vtr === 'number').map((user) => user.vtr!)),
    campaign_term_ratio_min: Math.min(
      ...twitterUsers
        .filter((user) => typeof user.campaign_term_ratio === 'number')
        .map((user) => user.campaign_term_ratio!),
    ),
    campaign_term_ratio_max: Math.max(
      ...twitterUsers
        .filter((user) => typeof user.campaign_term_ratio === 'number')
        .map((user) => user.campaign_term_ratio!),
    ),
    original_tweets_ratio_min: Math.min(
      ...twitterUsers
        .filter((user) => typeof user.original_tweets_ratio === 'number')
        .map((user) => user.original_tweets_ratio!),
    ),
    original_tweets_ratio_max: Math.max(
      ...twitterUsers
        .filter((user) => typeof user.original_tweets_ratio === 'number')
        .map((user) => user.original_tweets_ratio!),
    ),
    avg_tweet_impr_min: Math.min(
      ...twitterUsers.filter((user) => typeof user.avg_tweet_impr === 'number').map((user) => user.avg_tweet_impr!),
    ),
    avg_tweet_impr_max: Math.max(
      ...twitterUsers.filter((user) => typeof user.avg_tweet_impr === 'number').map((user) => user.avg_tweet_impr!),
    ),
    influence_score_min: Math.min(
      ...twitterUsers.filter((user) => typeof user.influence_score === 'number').map((user) => user.influence_score!),
    ),
    influence_score_max: Math.max(
      ...twitterUsers.filter((user) => typeof user.influence_score === 'number').map((user) => user.influence_score!),
    ),
    total_users: twitterUsers.length,
  };
  return metricsRange;
};

export const getTwitterUsersCategories = (twitterUsers: TwitterUserSearchResult[]) => {
  let categories: string[] = [];
  twitterUsers.forEach((user) => user.categories?.forEach((category) => categories.push(category)));
  return dedupArray(categories);
};

export const getTwitterUsersLanguages = (twitterUsers: TwitterUserSearchResult[]) => {
  let languages: string[] = [];
  twitterUsers.forEach((user) => user.languages?.forEach((language) => languages.push(language)));
  return dedupArray(languages);
};

export const getTwitterUsersLocations = (twitterUsers: TwitterUserSearchResult[]) => {
  let locations: string[] = [];
  twitterUsers.forEach((user) => user.location && locations.push(user.location));
  return dedupArray(locations);
};
