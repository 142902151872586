import { Button, Stack, Typography } from '@mui/material';
import { Cohort, CohortProjectType, DefaultCohortData } from 'common/types/Cohort';
import { FC, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { BuildCohort } from 'pages/WalletSelector/CohortV2/Edit/BuildCohort';
import { CheckOutlined } from '@mui/icons-material';
import { ChooseProject } from 'pages/WalletSelector/CohortV2/Edit/ChooseProject';
import ClientAPI from 'common/ClientAPI';
import { CohortInfoEditDialog } from 'pages/WalletSelector/CohortV2/CohortInfoEditDialog';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { Uris } from 'Uris';
import classes from './CreateCohort.module.scss';
import { cloneDeep } from 'lodash';
import { useLeavePageConfirm } from 'common/hooks/useLeavePageConfirm';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';

enum CreateCohortStep {
  CHOOSE_PROJECT = 'chooseProject',
  BUILD_COHORT = 'buildCohort',
}

export const CreateCohort: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showMessage } = useMessage();
  const { track } = useTracking();
  // check whether there is any cohort data in state
  // if yes -> use it. if no -> use default cohort data
  const [cohort, setCohort] = useState<Cohort>(state?.cohort ? state?.cohort : cloneDeep(DefaultCohortData));
  const [step, setStep] = useState<CreateCohortStep>(
    state?.cohort ? CreateCohortStep.BUILD_COHORT : CreateCohortStep.CHOOSE_PROJECT,
  );
  const [cohortInfoDialogOpened, setCohortInfoDialogOpened] = useState<boolean>(false);
  const [successDialogOpened, setSuccessDialogOpened] = useState<boolean>(false);

  /** Window close with edited content not saved */
  useLeavePageConfirm(!successDialogOpened); // when success, disable leave page confirm

  const onCohortCreate = useCallback(
    async (cohort: Cohort) => {
      await ClientAPI.createCohort(cohort)
        .then(({ status, message, data }) => {
          if (status !== 'success' || !data) {
            showMessage(`Create cohort failed: ${message}`);
            return;
          }
          setCohort(cohort);
          setSuccessDialogOpened(true);
          track('form_submit', {
            sub_event: 'cohort_created',
            custom_props: { cohort_id: data.id, cohort_name: cohort.name, predict_size: cohort.wallet_count },
          });
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    },
    [showMessage, track],
  );

  const onCohortEdit = useCallback((cohort: Cohort) => {
    setCohort(cohort);
  }, []);

  const onViewCohortsBtnClicked = useCallback(() => {
    setSuccessDialogOpened(false);
    navigate(Uris.Pages.WalletSelector.Index);
  }, [navigate]);

  const mainContent = useMemo(() => {
    switch (step) {
      case CreateCohortStep.CHOOSE_PROJECT: // Choose Project
        return (
          <ChooseProject
            onNext={(projectType: CohortProjectType) => {
              setCohort((old) => ({ ...old, project_type: projectType }));
              setStep(CreateCohortStep.BUILD_COHORT);
            }}
          />
        );
      case CreateCohortStep.BUILD_COHORT: // Build Cohort
        return (
          <BuildCohort
            cohort={cohort}
            onCohortEdit={onCohortEdit}
            onCreate={() => setCohortInfoDialogOpened(true)}
            onBack={() => {
              setStep(CreateCohortStep.CHOOSE_PROJECT);
            }}
          ></BuildCohort>
        );
      default:
        return <></>;
    }
  }, [step, cohort, onCohortEdit]);

  return (
    <Stack className={classes.root}>
      {mainContent}
      {/* confirm dialog */}
      {cohortInfoDialogOpened ? (
        <CohortInfoEditDialog
          open
          cohort={cohort}
          onSave={(cohort) => onCohortCreate(cohort)}
          onCancel={() => setCohortInfoDialogOpened(false)}
        />
      ) : null}

      {/* create success dialog */}
      {successDialogOpened ? (
        <CommonDialog
          open
          mainContent={
            <Stack className={classes.dialog} alignItems='center' spacing={2}>
              <CommonChip>
                <CheckOutlined fontSize='large' />
              </CommonChip>
              <Stack spacing={2}>
                <Typography variant='h6'>Cohort {cohort.name} was created successfully! </Typography>
                <Typography variant='body1'>Now is ready for analysis and targeting.</Typography>
              </Stack>
            </Stack>
          }
          footer={
            <Button variant='contained' onClick={onViewCohortsBtnClicked}>
              Back to list
            </Button>
          }
          onDialogClose={onViewCohortsBtnClicked}
        />
      ) : null}
    </Stack>
  );
};
