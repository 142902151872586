import { useCallback, useContext, useEffect, useState } from 'react';

import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { useMessage } from 'components/message/useMessage';

export const useLocations = () => {
  const { showMessage } = useMessage();
  const { locations$ } = useContext(ExtensionContext);
  const [locations, setLocations] = useState<string[] | undefined>(locations$.getValue());
  const [loading, setLoading] = useState<boolean>(true);

  const fetchLocations = useCallback(async () => {
    setLoading(true);
    await ExtensionAPI.getTwitterUserLocations()
      .then(({ data }) => {
        locations$.next(data);
        setLocations(data);
        return;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showMessage, locations$]);

  useEffect(() => {
    if (locations) {
      setLoading(false);
      return;
    }
    fetchLocations();
  }, [locations, fetchLocations]);

  return { loading, value: locations };
};
