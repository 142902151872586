import { Breadcrumbs, Button, Divider, Link, Stack, Typography } from '@mui/material';
import { Cohort, isCohortValid } from 'common/types/Cohort';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FilterListOutlined, NavigateNextOutlined, TagOutlined } from '@mui/icons-material';
import { useAsync, useObservable } from 'react-use';

import { AdjustCriteriaDialog } from 'pages/WalletSelector/CohortV2/Edit/Dialog/AdjustCriteriaDialog';
import ClientAPI from 'common/ClientAPI';
import { CohortFilter } from 'common/types/CohortFilter';
import { DataContext } from 'contexts/DataContext';
import { EditCategory } from 'pages/WalletSelector/CohortV2/Edit/Drawer/EditCategory';
import { EditFilter } from 'pages/WalletSelector/CohortV2/Edit/Drawer/EditFilter';
import { FilterDetails } from 'components/filter/FilterDetails';
import { PredictionPanel } from 'components/PredictionPanel/PredictionPanel';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './BuildCohort.module.scss';
import { renderCategory } from 'components/Category/renderCategory';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router-dom';

interface BuildCohortProps {
  cohort: Cohort;
  onCohortEdit?: (cohort: Cohort) => void;
  onCreate?: () => void;
  onBack?: () => void;
}

const maxWalletCount = 30000000;

export const BuildCohort: FC<BuildCohortProps> = ({ cohort, onCohortEdit, onCreate, onBack }) => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();

  const [editCategoryOpened, setEditCategoryOpened] = useState<boolean>(true);
  const [editFilterOpened, setEditFilterOpened] = useState<boolean>(false);
  const { activeWalletCount$ } = useContext(DataContext);
  const activeWalletCount = useObservable(activeWalletCount$);
  const [walletCount, setWalletCount] = useState<number | undefined>(cohort.wallet_count);
  const [lastUpdated, setLastUpdated] = useState<number | undefined>(cohort.last_run_at);
  const [resyncing, setResyncing] = useState<boolean>(false);
  const [adjustCriteriaDialogOpened, setAdjustCriteriaDialogOpened] = useState<boolean>(false);

  const { error: growing3CategoriesError, value: growing3Categories } = useAsync(async () => {
    return (await ClientAPI.getGrowing3Categories()).data;
  }, []);

  const { error: customizedCategoriesError, value: customizedCategories } = useAsync(async () => {
    return (await ClientAPI.getCustomizedCategories()).data;
  }, []);

  useEffect(() => {
    growing3CategoriesError &&
      growing3CategoriesError &&
      showMessage(growing3CategoriesError?.message || 'Unknown Error', 'error');
  }, [growing3CategoriesError, showMessage]);

  useEffect(() => {
    customizedCategoriesError &&
      customizedCategoriesError &&
      showMessage(customizedCategoriesError?.message || 'Unknown Error', 'error');
  }, [customizedCategoriesError, showMessage]);

  const resyncWalletCount = useCallback(
    async (cohort: Cohort) => {
      setResyncing(true);
      await ClientAPI.getCohortWalletCount(cohort)
        .then(({ data }) => {
          if (typeof data?.predict_number === 'number') {
            setWalletCount(data?.predict_number);
            setLastUpdated(new Date().getTime() / 1000);
            onCohortEdit?.({
              ...cohort,
              wallet_count: data?.predict_number,
            });
            return;
          }
          showMessage(`Predict Cohort wallet count failed`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        })
        .finally(() => {
          setResyncing(false);
        });
    },
    [showMessage, onCohortEdit],
  );

  const cohortValid = useMemo(() => {
    // check cohort is valid or not
    return isCohortValid(cohort);
  }, [cohort]);

  return (
    <Stack className={classes.root} direction='row' spacing={8}>
      <Stack className={classes.left}>
        <PredictionPanel
          direction='vertical'
          resyncing={resyncing}
          selectedCount={walletCount}
          totalCount={activeWalletCount || 0}
          lastUpdated={lastUpdated}
          onPredict={() => resyncWalletCount(cohort)}
        ></PredictionPanel>
      </Stack>
      <Stack className={classes.right} spacing={8}>
        <Breadcrumbs separator={<NavigateNextOutlined />} aria-label='breadcrumb'>
          <Link
            variant='subtitle2'
            underline='none'
            className={classes.link}
            onClick={() => navigate(Uris.Pages.WalletSelector.Index)}
          >
            Wallet Selector
          </Link>
          <Typography variant='subtitle2'>Create Cohort</Typography>
        </Breadcrumbs>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant='h4'>Building Cohorts with Categories and Advanced Filters</Typography>
            <Typography variant='body1'>
              Defining each filter conditions for your cohorts. This step allows you to precisely target your ads to
              specific groups of users based on their assets and behaviors.
            </Typography>
          </Stack>
          <Stack direction='row-reverse' spacing={2}>
            <Button
              id='btn-create-cohort-save'
              variant='contained'
              disabled={resyncing || typeof walletCount !== 'number' || !cohortValid}
              onClick={() => {
                if (typeof walletCount !== 'number') return;
                if (walletCount >= maxWalletCount) {
                  setAdjustCriteriaDialogOpened(true);
                  return;
                }
                onCreate?.();
              }}
            >
              Save
            </Button>
            <Button variant='outlined' onClick={onBack}>
              Back
            </Button>
          </Stack>
        </Stack>
        <Stack id='area-create-cohort-detail-view' className={classes.conditions} spacing={3}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' spacing={2} alignItems='center'>
              <TagOutlined />
              <Typography variant='h6'>Wallet Category</Typography>
            </Stack>
            <Button variant='outlined' onClick={() => setEditCategoryOpened(true)}>
              Edit
            </Button>
          </Stack>
          <Stack direction='row' flexWrap='wrap'>
            {cohort.growing3_categories?.length || cohort.customized_categories?.length ? (
              <>
                {(cohort.growing3_categories || []).map((categoryId) => {
                  const category = growing3Categories?.find((category) => category.id === categoryId);
                  if (!category) return null;
                  return (
                    <Stack
                      className={classNames(classes.category, category.disabled && classes.disabled)}
                      key={category.id}
                    >
                      {renderCategory(category)}
                    </Stack>
                  );
                })}
                {(cohort.customized_categories || []).map((categoryId) => {
                  const category = customizedCategories?.find((category) => category.id === categoryId);
                  if (!category) return null;
                  return (
                    <Stack
                      className={classNames(classes.category, category.disabled && classes.disabled)}
                      key={category.id}
                    >
                      {renderCategory(category)}
                    </Stack>
                  );
                })}
              </>
            ) : (
              <Stack className={classes.category}>
                <Typography variant='label1'>All Wallet Addresses</Typography>
              </Stack>
            )}
          </Stack>
          <Stack className={classes.filters}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack direction='row' spacing={2} alignItems='center'>
                <FilterListOutlined />
                <Typography variant='h6'>Advanced Filter Applied</Typography>
              </Stack>
              <Button variant='outlined' onClick={() => setEditFilterOpened(true)}>
                Edit
              </Button>
            </Stack>
            <Stack className={classes.divider}>
              <Divider />
            </Stack>
            <FilterDetails cohort={cohort} />
          </Stack>
        </Stack>
      </Stack>

      {/* drawer */}
      <EditCategory
        open={editCategoryOpened}
        growing3Categories={growing3Categories || []}
        customizedCategories={customizedCategories || []}
        cohort={cohort}
        onNext={(growing3Categories: number[] | undefined, customizedCategories: number[] | undefined) => {
          onCohortEdit?.({
            ...cohort,
            growing3_categories: growing3Categories?.length ? growing3Categories : undefined,
            customized_categories: customizedCategories?.length ? customizedCategories : undefined,
          });
          setEditCategoryOpened(false);
          setEditFilterOpened(true);
        }}
        onClose={() => setEditCategoryOpened(false)}
      />
      <EditFilter
        open={editFilterOpened}
        cohort={cohort}
        growing3Categories={growing3Categories || []}
        customizedCategories={customizedCategories || []}
        onNext={(filter: CohortFilter) => {
          const newCohort = { ...cohort, filter };
          onCohortEdit?.(newCohort);
          resyncWalletCount(newCohort);
          setEditFilterOpened(false);
        }}
        onClose={() => {
          setEditFilterOpened(false);
          setEditCategoryOpened(true);
        }}
      />

      {adjustCriteriaDialogOpened ? (
        <AdjustCriteriaDialog
          open
          onEdit={() => {
            setAdjustCriteriaDialogOpened(false);
            setEditFilterOpened(true);
          }}
          onCancel={() => {
            setAdjustCriteriaDialogOpened(false);
          }}
        />
      ) : null}
    </Stack>
  );
};
