import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { AdGroupPublishResult } from 'components/TwitterAds/publish/content/PublishAdGroupResult';
import { Audience } from 'common/types/Audience';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { HumanIcon } from 'components/icons/HumanIcon';
import { SearchOutlined } from '@mui/icons-material';
import { Spinner } from 'components/common/Spinner';
import { TwitterAdsAccount } from 'common/types/TwitterAds';
import { Uris } from 'Uris';
import classes from './SelectAdGroup.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

interface SelectAdGroupProps {
  audience: Audience;
  accountId: string;
  accounts: TwitterAdsAccount[];
  levels: string[];
  onAccountIdChanged?: (accountId: string) => void;
  onAdGroupsPublished?: (result: AdGroupPublishResult[]) => void;
}

export const SelectAdGroup: FC<SelectAdGroupProps> = ({
  audience,
  accountId,
  accounts,
  levels,
  onAccountIdChanged,
  onAdGroupsPublished,
}) => {
  const { showMessage } = useMessage();

  const [selectedAdGroups, setSelectedAdGroups] = useState<string[]>([]);
  const [searchStr, setSearchStr] = useState<string>('');
  const [publishing, setPublishing] = useState<boolean>(false);

  const {
    loading: campaignsLoading,
    error: campaignsError,
    value: campaigns,
  } = useAsync(async () => {
    return (await ClientAPI.getTwitterAdsCampaigns(accountId)).data;
  }, [accountId]);

  const {
    loading: adGroupsLoading,
    error: adGroupsError,
    value: adGroups,
  } = useAsync(async () => {
    return (await ClientAPI.getTwitterAdsAdGroups(accountId)).data;
  }, [accountId]);

  useEffect(() => {
    campaignsError && showMessage(campaignsError?.message || 'Unknown Error', 'error');
  }, [campaignsError, showMessage]);

  useEffect(() => {
    adGroupsError && showMessage(adGroupsError?.message || 'Unknown Error', 'error');
  }, [adGroupsError, showMessage]);

  useEffect(() => {
    setSelectedAdGroups([]);
  }, [accountId]);

  const handleAdGroupsChange = useCallback((value: string | string[]) => {
    if (typeof value === 'string') {
      const newValues = value.split(',');
      setSelectedAdGroups(newValues);
    } else {
      setSelectedAdGroups(value);
    }
  }, []);

  const filteredAdGroups = useMemo(() => {
    if (!searchStr) return adGroups;
    const str = searchStr.toUpperCase();
    return adGroups?.filter((adGroup) => {
      const campaign = campaigns?.find((campaign) => campaign.id === adGroup.campaign_id);
      return (
        adGroup.name.toUpperCase().indexOf(str) > -1 || (campaign && campaign.name.toUpperCase().indexOf(str) > -1)
      );
    });
  }, [searchStr, adGroups, campaigns]);

  const onBtnPublishClicked = useCallback(
    async (adGroupIds: string[]) => {
      if (typeof audience.id !== 'number') return;
      setPublishing(true);

      await ClientAPI.publishInfluencers(audience.id, levels, accountId, adGroupIds)
        .then(({ data }) => {
          if (!data) {
            showMessage('publish failed');
            return;
          }
          const results: AdGroupPublishResult[] = [];
          data.forEach((d) => {
            const adGroup = adGroups?.find((adGroup) => adGroup.id === d.ad_group_id);
            if (!adGroup) return;
            const compaign = campaigns?.find((campaign) => campaign.id === adGroup.campaign_id);
            if (!compaign) return;
            results.push({
              accountId: accountId,
              adGroupId: adGroup.id,
              adGroupName: adGroup.name,
              campaignId: compaign.id,
              campaignName: compaign.name,
              url: Uris.External.TwitterAdGroupEdit.replace(':accountId', accountId)
                .replace(':campaignId', parseInt(compaign.id, 36).toString())
                .replace(':adGroupId', parseInt(adGroup.id, 36).toString()),
              error: d.error,
            });
          });
          onAdGroupsPublished?.(results);
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        })
        .finally(() => {
          setPublishing(false);
        });
    },
    [audience, accountId, levels, adGroups, campaigns, showMessage, onAdGroupsPublished],
  );

  return (
    <Stack className={classes.root} spacing={1}>
      <Stack spacing={3} className={classes.content}>
        <Select
          className={classes.select}
          value={accountId}
          renderValue={(accountId) => {
            const account = accounts.find((account) => account.id === accountId);
            if (!account) return null;
            return (
              <Stack direction='row' alignItems='center' spacing={2} className={classes.value}>
                <HumanIcon fontSize='large' className={classes.icon} />
                <Stack>
                  <Typography className={classes.text}>{account.name.trim()}</Typography>
                  <Typography variant='label1' className={classes.subText}>
                    ID: {account.id}
                  </Typography>
                </Stack>
              </Stack>
            );
          }}
          onChange={(e) => e.target.value && onAccountIdChanged?.(e.target.value)}
        >
          {accounts.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              <Stack direction='row' alignItems='center' spacing={2} className={classes.accountOption}>
                <HumanIcon fontSize='large' className={classes.icon} />
                <Stack>
                  <Typography className={classes.text}>{account.name.trim()}</Typography>
                  <Typography variant='label1' className={classes.subText}>
                    ID: {account.id}
                  </Typography>
                </Stack>
              </Stack>
            </MenuItem>
          ))}
        </Select>
        <Stack className={classes.title} alignItems='center' spacing={2}>
          <CommonChip>
            <HumanIcon fontSize='large' />
          </CommonChip>
          <Stack spacing={1}>
            {adGroupsLoading || adGroups?.length ? (
              <Typography variant='h6'>Select Ad groups</Typography>
            ) : (
              <>
                <Typography variant='h6'>No ad group found under this ad account.</Typography>
                <Typography variant='body1' className={classes.subtitleText}>
                  Before publishing your influencer list, make sure to set up the campaign on Twitter.
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
        {campaignsLoading || adGroupsLoading ? (
          <Stack alignItems='center'>
            <Spinner />
          </Stack>
        ) : !adGroups?.length ? null : (
          <FormControl className={classes.adGroups}>
            <InputLabel>Ad groups selected</InputLabel>
            <Select
              multiple
              className={classes.select}
              value={selectedAdGroups}
              MenuProps={{
                disableAutoFocusItem: true,
                sx: {
                  height: '50vh',
                  width: '520px',
                },
              }}
              label='Ad groups selected'
              renderValue={(adGroupIds) => {
                const string = adGroupIds
                  .map((adGroupId) => adGroups?.find((adGroup) => adGroup.id === adGroupId)?.name)
                  .join(', ');
                return <Typography className={classes.value}>{string}</Typography>;
              }}
              onChange={(e) => handleAdGroupsChange(e.target.value)}
            >
              <OutlinedInput
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchOutlined />
                  </InputAdornment>
                }
                placeholder='Search'
                sx={{ width: '100%', '& fieldset': { borderColor: 'transparent' } }}
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value.toUpperCase())}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Divider />
              {(filteredAdGroups || []).map((adGroup) => (
                <MenuItem key={adGroup.id} divider value={adGroup.id}>
                  <Stack direction='row' className={classes.adGroupOption} alignItems='center' spacing={2}>
                    <Stack direction='row' alignItems='center' className={classes.left} spacing={1}>
                      <Checkbox checked={selectedAdGroups && selectedAdGroups.indexOf(adGroup.id) > -1} />
                      <Stack>
                        <Typography variant='body1' className={classes.text}>
                          {adGroup.name}
                        </Typography>
                        <Typography variant='body2' className={classes.subText}>
                          ID: {adGroup.id}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography variant='label2' className={classes.right}>
                      {(campaigns || []).find((campaign) => campaign.id === adGroup.campaign_id)?.name}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>
      <Stack direction='row-reverse' spacing={1} className={classes.actions}>
        {adGroupsLoading || adGroups?.length ? (
          <Button
            variant='contained'
            startIcon={publishing ? <Spinner size={16} /> : null}
            disabled={!selectedAdGroups.length || publishing || campaignsLoading || adGroupsLoading}
            onClick={() => selectedAdGroups.length && onBtnPublishClicked(selectedAdGroups)}
          >
            Publish
          </Button>
        ) : (
          <Button variant='contained' onClick={() => window.open(Uris.External.TwitterAds, '_blank')}>
            Setup Campaign
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
