import { useCallback, useContext, useEffect, useState } from 'react';

import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { useMessage } from 'components/message/useMessage';

export const useCategories = () => {
  const { showMessage } = useMessage();
  const { projectCategories$, nonProjectCategories$ } = useContext(ExtensionContext);
  const [projectCategories, setProjectCategories] = useState<string[] | undefined>(projectCategories$.getValue());
  const [nonProjectCategories, setNonProjectCategories] = useState<string[] | undefined>(
    nonProjectCategories$.getValue(),
  );
  const [loading, setLoading] = useState<boolean>(true);

  const fetchProjectCategories = useCallback(async () => {
    setLoading(true);
    await ExtensionAPI.getTwitterUserCategories(true)
      .then(({ data }) => {
        projectCategories$.next(data);
        setProjectCategories(data);
        return;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showMessage, projectCategories$]);

  const fetchNonProjectCategories = useCallback(async () => {
    setLoading(true);
    await ExtensionAPI.getTwitterUserCategories(false)
      .then(({ data }) => {
        nonProjectCategories$.next(data);
        setNonProjectCategories(data);
        return;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showMessage, nonProjectCategories$]);

  useEffect(() => {
    if (projectCategories) return;
    fetchProjectCategories();
  }, [projectCategories, fetchProjectCategories]);

  useEffect(() => {
    if (nonProjectCategories) return;
    fetchNonProjectCategories();
  }, [nonProjectCategories, fetchNonProjectCategories]);

  useEffect(() => {
    if (projectCategories && nonProjectCategories) {
      setLoading(false);
      return;
    }
  }, [projectCategories, nonProjectCategories]);

  return { loading, value: (projectCategories || []).concat(nonProjectCategories || []) };
};
