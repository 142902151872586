import { AddLinkOutlined, CloseOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogProps,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import { CommonChip } from 'components/chip/CommonChip';
import ExtensionAPI from 'common/ExtensionAPI';
import { InfluencerPostLink } from 'common/types/Extension/InfluencerCampaign';
import classes from './AddPostLinkDialog.module.scss';
import { useMessage } from 'components/message/useMessage';

interface AddPostLinkDialogProps extends DialogProps {
  postLink: InfluencerPostLink;
  onAdd?: () => void;
  onCancel?: () => void;
}

export const AddPostLinkDialog: FC<AddPostLinkDialogProps> = ({ postLink, open, onAdd, onCancel, ...props }) => {
  const { showMessage } = useMessage();
  const [editPostLink, setEditPostLink] = useState<InfluencerPostLink>(postLink);

  const addPostLink = useCallback(async () => {
    await ExtensionAPI.createInfluencerCampaignPostLink(editPostLink)
      .then(({ status, data }) => {
        if (status === 'success' && data) {
          onAdd?.();
          return;
        }
        showMessage(`Add post link failed: ${status}`, 'error');
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      });
  }, [editPostLink, showMessage, onAdd]);

  const onBtnAddClicked = useCallback(() => {
    addPostLink();
  }, [addPostLink]);

  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <AddLinkOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={2} className={classes.fields}>
              <Typography variant='h6' textAlign='center'>
                Add Post Link
              </Typography>
              <FormControl className={classes.input}>
                <InputLabel>Name</InputLabel>
                <OutlinedInput
                  size='medium'
                  label='Name'
                  value={editPostLink.name}
                  onChange={(e) => setEditPostLink((old) => ({ ...old, name: e.target.value }))}
                />
              </FormControl>
              <FormControl className={classes.input}>
                <InputLabel>Link</InputLabel>
                <OutlinedInput
                  size='medium'
                  label='Link'
                  value={editPostLink.link}
                  onChange={(e) => setEditPostLink((old) => ({ ...old, link: e.target.value }))}
                />
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='contained' onClick={onBtnAddClicked}>
            Add
          </Button>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
