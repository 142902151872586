import { ArrowOutwardOutlined, Message } from '@mui/icons-material';
import { Divider, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { InfluencerCampaign, InfluencerMessage } from 'common/types/Extension/InfluencerCampaign';

import { TwitterUserMeta } from 'common/types/Extension/TwitterUser';
import { ViewInfluencersDialog } from 'components/InfluencerCampaign/Edit/Dialog/ViewInfluencersDialog';
import classes from './CampaignMessagesDetail.module.scss';
import { colorPrimary40 } from 'common/params';

interface CampaignMessagesDetailProps {
  campaign: InfluencerCampaign;
}

export const CampaignMessagesDetail: FC<CampaignMessagesDetailProps> = ({ campaign }) => {
  const [viewInfluencersDialogOpened, setViewInfluencersDialogOpened] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<InfluencerMessage | undefined>(undefined);

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack className={classes.container} spacing={3}>
        <Typography>Messages Sent</Typography>
        <Divider />
        <Stack className={classes.messages} spacing={3}>
          {(campaign.messages || []).map((message, idx) => {
            const totalReceivers: TwitterUserMeta[] = [];
            const receiverSet = new Set<string>();
            (campaign.threads || [])
              .filter((thread) => thread.influencer_message_id === message.id)
              .forEach((thread) => {
                if (thread.receiver && !receiverSet.has(thread.receiver_id)) {
                  totalReceivers.push(thread.receiver);
                  receiverSet.add(thread.receiver_id);
                }
              });
            (message.sent_messages || []).forEach((sent_message) => {
              const receiver = campaign.threads?.find(
                (thread) => thread.receiver_id === sent_message.receiver_id,
              )?.receiver;
              if (receiver && !receiverSet.has(sent_message.receiver_id)) {
                totalReceivers.push(receiver);
                receiverSet.add(sent_message.receiver_id);
              }
            });
            return (
              <Stack spacing={3} key={message.id}>
                {idx > 0 ? <Divider /> : null}
                <Stack spacing={2}>
                  <Stack direction='row' color={colorPrimary40} alignItems='center' spacing={1}>
                    <Message fontSize='small' />
                    <Typography>Message {idx + 1}</Typography>
                  </Stack>
                  <Stack spacing={3}>
                    <Typography className={classes.content}>{message.content}</Typography>
                    <Stack direction='row' spacing={1}>
                      <Stack direction='row' alignItems='center' spacing={0.5}>
                        <ArrowOutwardOutlined
                          style={{ transform: 'rotate(180deg)' }}
                          fontSize='small'
                          color='primary'
                        />
                        <Typography color={colorPrimary40}>Influencers: </Typography>
                      </Stack>
                      <Typography>
                        {Array.from(new Set(totalReceivers))
                          .slice(0, 3)
                          .map((receiver) => receiver?.username)
                          .join(', ')}
                        {totalReceivers.length > 3 ? (
                          <span>
                            ... and{' '}
                            <span
                              className={classes.viewMore}
                              onClick={() => {
                                setSelectedMessage(message);
                                setViewInfluencersDialogOpened(true);
                              }}
                            >
                              {totalReceivers.length - 3} more
                            </span>
                          </span>
                        ) : (
                          ''
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>

      {viewInfluencersDialogOpened && selectedMessage ? (
        <ViewInfluencersDialog
          joinSent
          threads={campaign.threads || []}
          message={selectedMessage}
          onClose={() => {
            setSelectedMessage(undefined);
            setViewInfluencersDialogOpened(false);
          }}
        />
      ) : null}
    </Stack>
  );
};
