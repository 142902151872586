import { Button, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { InfluencerMessage, InfluencerThread } from 'common/types/Extension/InfluencerCampaign';

import { AdsAudienceIcon } from 'components/icons/AdsAudienceIcon';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { TwitterUserMeta } from 'common/types/Extension/TwitterUser';
import classes from './ViewInfluencersDialog.module.scss';

interface ViewInfluencersDialogProps {
  joinSent?: boolean;
  threads: InfluencerThread[];
  message: InfluencerMessage;
  onClose?: () => void;
}

export const ViewInfluencersDialog: FC<ViewInfluencersDialogProps> = ({ joinSent, threads, message, onClose }) => {
  const receivers = useMemo(() => {
    const totalReceivers: TwitterUserMeta[] = [];
    const receiverSet = new Set<string>();
    threads
      .filter((thread) => thread.influencer_message_id === message.id)
      .forEach((thread) => {
        if (thread.receiver && !receiverSet.has(thread.receiver_id)) {
          totalReceivers.push(thread.receiver);
          receiverSet.add(thread.receiver_id);
        }
      });
    if (joinSent) {
      (message.sent_messages || []).forEach((sent_message) => {
        const receiver = threads?.find((thread) => thread.receiver_id === sent_message.receiver_id)?.receiver;
        if (receiver && !receiverSet.has(sent_message.receiver_id)) {
          totalReceivers.push(receiver);
          receiverSet.add(sent_message.receiver_id);
        }
      });
    }
    return totalReceivers;
  }, [threads, joinSent, message]);

  return (
    <CommonDialog
      open={true}
      mainContent={
        <Stack spacing={3} alignItems='center' className={classes.dialog}>
          <CommonChip>
            <AdsAudienceIcon fontSize='large' />
          </CommonChip>
          <Typography variant='h6' className={classes.title}>
            Influencers Sent
          </Typography>
          <Typography className={classes.content}>
            {receivers.map((receiver) => receiver?.username).join(', ')}
          </Typography>
        </Stack>
      }
      footer={
        <Stack direction='row' justifyContent='center' className={classes.actions}>
          <Button
            variant='outlined'
            onClick={() => {
              onClose?.();
            }}
          >
            Close
          </Button>
        </Stack>
      }
      onDialogClose={onClose}
    />
  );
};
