import { useCallback, useContext, useEffect, useState } from 'react';

import AccountsAPI from 'common/AccountsAPI';
import { AccountsContext } from 'contexts/AccountsContext';
import { SubscriptionPlan } from 'common/types/Accounts/SubscriptionPlan';
import { useMessage } from 'components/message/useMessage';

export const useSubscriptionPlans = () => {
  const { showMessage } = useMessage();
  const { subscriptionPlans$ } = useContext(AccountsContext);
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[] | undefined>(
    subscriptionPlans$.getValue(),
  );
  const [loading, setLoading] = useState<boolean>(true);

  const fetchSubscriptionPlans = useCallback(async () => {
    setLoading(true);
    await AccountsAPI.getSubscriptionPlans()
      .then(({ data }) => {
        if (data) {
          subscriptionPlans$.next(data);
          setSubscriptionPlans(data);
        }
      })
      .catch((error) => {
        const statusCode = error?.cause || 0;
        if (statusCode !== 404) {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showMessage, subscriptionPlans$]);

  useEffect(() => {
    if (subscriptionPlans) {
      setLoading(false);
      return;
    }
    fetchSubscriptionPlans();
  }, [subscriptionPlans, fetchSubscriptionPlans]);

  return { loading, value: subscriptionPlans };
};
