import {
  Button,
  Dialog,
  DialogProps,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CheckCircleOutline,
  CloseOutlined,
  ContentCopyOutlined,
  LinkOutlined,
  OutboundOutlined,
} from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { colorPrimary40, colorSurface1 } from 'common/params';

import AccountsAPI from 'common/AccountsAPI';
import { CreditPlan } from 'common/types/Accounts/CreditPlan';
import { RoundStarIcon } from 'components/icons/RoundStarIcon';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './CreditDialog.module.scss';
import copy from 'copy-to-clipboard';
import { getCurrencySymbol } from 'common/utils';
import { useAccountsUserProfile } from 'common/hooks/Accounts/useAccountsUserProfile';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

interface CreditDialogProps extends DialogProps {
  onBtnCancelClicked?: () => void;
  onBtnUpgradeClicked?: () => void;
}

enum EarnCreditMethod {
  TOP_UP = 'Top up',
  REFER_FRIEND = 'Refer a friend',
  UPGRADE = 'Upgrade',
}

export const CreditDialog: FC<CreditDialogProps> = ({ open, onBtnCancelClicked, onBtnUpgradeClicked, ...props }) => {
  const { showMessage } = useMessage();
  const [selectedMethod, setSelectedMethod] = useState<EarnCreditMethod>(EarnCreditMethod.TOP_UP);
  const [selectedPlan, setSelectedPlan] = useState<CreditPlan | undefined>(undefined);
  const [copied, setCopied] = useState<boolean>(false);
  const [purchasing, setPurchasing] = useState<boolean>(false);

  const { loading, value: creditPlans } = useAsync(async () => {
    return (await AccountsAPI.getCreditPlans()).data;
  }, []);

  const { value: user } = useAccountsUserProfile();

  const createCreditPlanCheckout = useCallback(
    async (plan: CreditPlan) => {
      if (purchasing) return;
      setPurchasing(true);
      await AccountsAPI.createCreditCheckout(plan)
        .then(({ message, data }) => {
          if (data?.url) {
            window.location.href = data.url;
            return;
          }
          showMessage(`Create checkout failed: ${message}`);
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        })
        .finally(() => {
          setPurchasing(false);
        });
    },
    [purchasing, showMessage],
  );

  const content = useMemo(() => {
    if (loading) return <Spinner />;
    switch (selectedMethod) {
      case EarnCreditMethod.TOP_UP:
        return (
          <Stack spacing={3}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <FormControl className={classes.input}>
                <InputLabel>Credits</InputLabel>
                <Select
                  label='Credits'
                  value={selectedPlan?.id || ''}
                  onChange={(e) => setSelectedPlan(creditPlans?.find((plan) => plan.id === e.target.value))}
                >
                  {(creditPlans || []).map((creditPlan) => (
                    <MenuItem value={creditPlan.id}>{creditPlan.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedPlan ? (
                <Typography>
                  {getCurrencySymbol(selectedPlan.currency)}
                  {selectedPlan.price}
                </Typography>
              ) : null}
            </Stack>
            <Stack direction='row' justifyContent='center'>
              <Button
                variant='contained'
                disabled={purchasing || !selectedPlan}
                onClick={() => {
                  if (!selectedPlan) return;
                  createCreditPlanCheckout(selectedPlan);
                }}
              >
                Purchase
              </Button>
            </Stack>
          </Stack>
        );
      case EarnCreditMethod.REFER_FRIEND:
        return (
          <Stack spacing={3}>
            <Stack direction='row' justifyContent='space-between' spacing={3}>
              <Typography variant='body2' className={classes.task}>
                Give 200 credits and earn 200 credits for each new referral who signs up for Growing3.
              </Typography>
              <Typography variant='body2' className={classes.green}>
                +200 credits
              </Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between' spacing={3}>
              <Typography variant='body2' className={classes.task}>
                Marketing campaign kind of thing
              </Typography>
              <Typography variant='body2' className={classes.green}>
                +450 credits
              </Typography>
            </Stack>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='flex-start'
              spacing={3}
              style={{ display: 'none' }} // remove this line when refer code is ready
            >
              <Stack direction='row' spacing={1.5} alignItems='center'>
                <LinkOutlined fontSize='small' />
                <Typography variant='body2'>https://growing3.ai/signup?r=1pu15eui94dqg1k</Typography>
              </Stack>
              <Stack spacing={0.5}>
                <Button
                  variant='contained'
                  startIcon={<ContentCopyOutlined />}
                  onClick={() => {
                    setCopied(true);
                    copy('https://growing3.ai/signup?r=1pu15eui94dqg1k');
                  }}
                >
                  Copy
                </Button>
                {copied ? (
                  <Stack direction='row' alignItems='center' spacing={0.5} className={classes.blue}>
                    <CheckCircleOutline fontSize='small' />
                    <Typography variant='body2'>Link copied</Typography>
                  </Stack>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
        );
      case EarnCreditMethod.UPGRADE:
        return (
          <Stack spacing={3}>
            <Stack>
              <Typography>Running low on credits?</Typography>
              <Typography variant='body2'>Upgrade to optimize your workflow with ease.</Typography>
            </Stack>
            <Stack direction='row' justifyContent='center'>
              <Button variant='contained' onClick={onBtnUpgradeClicked}>
                Upgrade
              </Button>
            </Stack>
          </Stack>
        );
    }
    return null;
  }, [
    loading,
    selectedMethod,
    creditPlans,
    selectedPlan,
    copied,
    purchasing,
    createCreditPlanCheckout,
    onBtnUpgradeClicked,
  ]);

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      maxWidth='lg'
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <Stack direction='row' className={classes.closeBtn}>
        <IconButton onClick={onBtnCancelClicked}>
          <CloseOutlined style={{ color: colorPrimary40 }} />
        </IconButton>
      </Stack>
      <Stack className={classes.root} spacing={3}>
        <Stack spacing={1} alignItems='center'>
          <Typography>Your account balance</Typography>
          <Stack direction='row' spacing={3} alignItems='center'>
            <RoundStarIcon className={classes.starIcon} />
            <Stack direction='row' alignItems='flex-end' spacing={0.5}>
              <Typography variant='h5'>{user?.credits.balance || 0} Credits</Typography>
              <Tooltip arrow title='View my plan'>
                <OutboundOutlined
                  className={classes.outboundIcon}
                  onClick={() => window.open(Uris.Pages.User.Subscription.Index, '_blank')}
                />
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>

        <Stack className={classes.bottom} spacing={3}>
          <Stack spacing={1}>
            <Typography variant='h6'>Get more credits</Typography>
            <Typography variant='body2'>Credits let you create and edit with Growing3. </Typography>
          </Stack>
          <Stack direction='row'>
            {Object.values(EarnCreditMethod).map((method, idx) => (
              <Stack
                key={idx}
                direction='row'
                justifyContent='center'
                className={classes.btn}
                onClick={() => setSelectedMethod(method)}
              >
                <Typography
                  variant='subtitle2'
                  className={classNames(classes.btnText, method === selectedMethod && classes.selected)}
                >
                  {method}
                </Typography>
              </Stack>
            ))}
          </Stack>
          {content}
        </Stack>
      </Stack>
    </Dialog>
  );
};
