import { Add, CloseOutlined, NavigateNextOutlined, PersonOutline } from '@mui/icons-material';
import { Breadcrumbs, Button, Link, Stack, Tooltip, Typography } from '@mui/material';
import { IntegrationAuth, IntegrationAuthProvider } from 'common/types/IntegrationAuth';
import { useAsync, useLocation } from 'react-use';
import { useCallback, useEffect, useState } from 'react';

import { AttributionSource } from 'common/types/AttributionSource';
import { AttributionSourceList } from 'components/GoogleAnalytics/AttributionSourceList';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import { CreateAttributionSourceDialog } from 'components/GoogleAnalytics/CreateAttributionSourceDialog';
import { Spinner } from 'components/common/Spinner';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './GAIntegration.module.scss';
import { setCookie } from 'common/utils';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router';

export const GAIntegration = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [auth, setAuth] = useState<IntegrationAuth | undefined>(state?.usr?.auth ? state?.usr?.auth : undefined);
  const { showMessage } = useMessage();
  const [addSourceDialogOpened, setAddSourceDialogOpened] = useState<boolean>(false);
  const [deleteSourceId, setDeleteSourceId] = useState<number | undefined>(undefined);
  const [disconnectDialogOpened, setDisconnectDialogOpened] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  const { value: authValue } = useAsync(async () => {
    if (auth) return undefined;
    return (await ClientAPI.getDefaultIntegrationAuth(IntegrationAuthProvider.GA)).data;
  }, []);

  useEffect(() => {
    if (authValue) setAuth(authValue);
  }, [authValue]);

  const signInGAOauth = useCallback(async () => {
    await ClientAPI.integrationOauth2(IntegrationAuthProvider.GA)
      .then(({ data: oauth_url }) => {
        if (!oauth_url) return;
        setCookie('redirect_url', window.location.href);
        window.location.href = oauth_url;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      });
  }, [showMessage]);

  const deleteGAOauth = useCallback(async () => {
    if (!auth) return;
    await ClientAPI.deleteIntegrationAuth(IntegrationAuthProvider.GA, auth.id)
      .then(({ data, message }) => {
        if (data) {
          setRefetch((old) => !old);
          setDisconnectDialogOpened(false);
          setAuth(undefined);
          return;
        }
        showMessage(`Disonnect failed, ${message}`);
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      });
  }, [showMessage, auth]);

  const {
    loading,
    error,
    value: attributionSources,
  } = useAsync(async () => {
    if (!auth) return undefined;
    return (await ClientAPI.getAttributionSources(IntegrationAuthProvider.GA)).data;
  }, [auth, refetch]);

  useEffect(() => {
    error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  const deleteAttributionSource = useCallback(async () => {
    if (deleteSourceId === undefined) return;
    await ClientAPI.deleteAttributionSource(IntegrationAuthProvider.GA, deleteSourceId)
      .then(({ data, message }) => {
        if (data) {
          setRefetch((old) => !old);
          setDeleteSourceId(undefined);
          return;
        }
        showMessage(`Delete attribution source failed, ${message}`);
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      });
  }, [deleteSourceId, showMessage]);

  const onAttributionEdit = useCallback(
    async (sourceId: number, data: AttributionSource) => {
      await ClientAPI.updateAttributionSource(IntegrationAuthProvider.GA, sourceId, data.disabled)
        .then(({ data, message }) => {
          if (data) {
            setRefetch((old) => !old);
            return;
          }
          showMessage(`Update attribution source failed, ${message}`);
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    },
    [showMessage],
  );

  const onAttributionDelete = useCallback((sourceId: number) => {
    setDeleteSourceId(sourceId);
  }, []);

  return (
    <Stack className={classes.root} spacing={4}>
      <Breadcrumbs separator={<NavigateNextOutlined />} aria-label='breadcrumb'>
        <Link
          variant='subtitle2'
          underline='none'
          className={classes.link}
          onClick={() => navigate(Uris.Pages.User.Integration.Index)}
        >
          Integration
        </Link>
        <Typography variant='subtitle2'>Google Analytics Properties & Apps</Typography>
      </Breadcrumbs>
      <Stack direction='row' alignItems='center' spacing={2}>
        {auth ? (
          <>
            <Stack
              direction='row'
              className={classNames(classes.account, classes.connected)}
              alignItems='center'
              spacing={1}
            >
              <Stack className={classNames(classes.icon, classes.connected)} alignItems='center'>
                <PersonOutline fontSize='small' />
              </Stack>
              <Typography>{auth.name} is set as your default account</Typography>
            </Stack>
            <Tooltip
              title={'This will remove all properties associated with this account from the Growing3 dashboard.'}
              placement='top'
              arrow
            >
              <Button variant='outlined' onClick={() => setDisconnectDialogOpened(true)}>
                Disconnect
              </Button>
            </Tooltip>
          </>
        ) : (
          <>
            <Stack direction='row' className={classes.account} alignItems='center' spacing={1}>
              <Stack className={classes.icon} alignItems='center'>
                <PersonOutline fontSize='small' />
              </Stack>
              <Typography>No Account Connected</Typography>
            </Stack>
            <Button variant='contained' onClick={signInGAOauth}>
              Connect
            </Button>
          </>
        )}
      </Stack>
      <Stack className={classes.bottom} spacing={3}>
        <Stack direction='row' className={classes.title} justifyContent='space-between'>
          <Typography variant='h3'>Google Analytics Properties & Apps</Typography>
          <Button
            variant='contained'
            startIcon={<Add />}
            disabled={!auth}
            onClick={() => setAddSourceDialogOpened(true)}
          >
            Add
          </Button>
        </Stack>
        {loading ? (
          <Spinner />
        ) : attributionSources?.length ? (
          <AttributionSourceList
            className={classes.table}
            attributionSources={attributionSources}
            onEdit={onAttributionEdit}
            onDelete={onAttributionDelete}
          />
        ) : (
          <Stack className={classes.init} spacing={3}>
            <Typography variant='h6'>No Properties & Apps Found</Typography>
            <Button
              className={classes.btn}
              variant='contained'
              disabled={!auth}
              onClick={() => setAddSourceDialogOpened(true)}
            >
              Getting Started
            </Button>
          </Stack>
        )}
      </Stack>
      {addSourceDialogOpened && auth ? (
        <CreateAttributionSourceDialog
          open
          integrationAuth={auth}
          attributionSources={attributionSources || []}
          onDialogClose={(refetch) => {
            if (refetch) setRefetch((old) => !old);
            setAddSourceDialogOpened(false);
          }}
        />
      ) : null}
      {typeof deleteSourceId === 'number' ? (
        <CommonDialog
          open
          mainContent={
            <Stack spacing={2} alignItems='center' className={classes.dialog} textAlign='center'>
              <CommonChip>
                <CloseOutlined fontSize='large' />
              </CommonChip>
              <Typography variant='h6'>Remove this property?</Typography>
              <Typography variant='body1'>You can add it again by clicking the Add button.</Typography>
            </Stack>
          }
          footer={
            <Stack direction='row' justifyContent='center' spacing={1}>
              <Button variant='contained' onClick={() => deleteAttributionSource()}>
                Remove
              </Button>
              <Button variant='outlined' onClick={() => setDeleteSourceId(undefined)}>
                Cancel
              </Button>
            </Stack>
          }
          onDialogClose={() => setDeleteSourceId(undefined)}
        ></CommonDialog>
      ) : null}
      {disconnectDialogOpened ? (
        <CommonDialog
          open={disconnectDialogOpened}
          mainContent={
            <Stack spacing={2} alignItems='center' className={classes.dialog} textAlign='center'>
              <CommonChip>
                <PersonOutline fontSize='large' />
              </CommonChip>
              <Typography variant='h6'>Disconnect this account?</Typography>

              <Typography variant='body1'>
                This action will remove all properties associated with this account from the Growing3 dashboard.
                <br />
                You can re-add them by re-authenticating.
              </Typography>
            </Stack>
          }
          footer={
            <Stack direction='row' justifyContent='center' spacing={1}>
              <Button variant='contained' onClick={() => deleteGAOauth()}>
                Disconnect
              </Button>
              <Button variant='outlined' onClick={() => setDisconnectDialogOpened(false)}>
                Cancel
              </Button>
            </Stack>
          }
          onDialogClose={() => setDisconnectDialogOpened(false)}
        ></CommonDialog>
      ) : null}
    </Stack>
  );
};
