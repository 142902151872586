import { useCallback, useContext, useEffect, useState } from 'react';

import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { FeatureLimit } from 'common/types/FeatureLimit';
import { useMessage } from 'components/message/useMessage';

export const useExtensionFeatureLimits = () => {
  const { showMessage } = useMessage();
  const { featureLimits$ } = useContext(ExtensionContext);
  const [featureLimits, setFeatureLimits] = useState<FeatureLimit[] | undefined>(featureLimits$.getValue());
  const [loading, setLoading] = useState<boolean>(true);

  const fetchFeatureLimits = useCallback(async () => {
    setLoading(true);
    await ExtensionAPI.getFeatureLimits()
      .then(({ data }) => {
        featureLimits$.next(data);
        setFeatureLimits(data);
        return;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showMessage, featureLimits$]);

  useEffect(() => {
    if (featureLimits) {
      setLoading(false);
      return;
    }
    fetchFeatureLimits();
  }, [featureLimits, fetchFeatureLimits]);

  return { loading, value: featureLimits };
};
