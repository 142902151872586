import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { colorPrimary10, colorPrimary40, colorSecondary40, colorSurface1 } from 'common/params';

import { Audience } from 'common/types/Audience';
import ClientAPI from 'common/ClientAPI';
import { Cohort } from 'common/types/Cohort';
import { CommonChip } from 'components/chip/CommonChip';
import classes from './CohortDeleteDialog.module.scss';
import { useMessage } from 'components/message/useMessage';

interface CohortDeleteDialogProps extends DialogProps {
  cohort: Cohort;
  onDeleteFailed?: (audiences: Audience[]) => void;
  onDelete?: () => void;
  onCancel?: () => void;
}

export const CohortDeleteDialog: FC<CohortDeleteDialogProps> = ({
  open,
  cohort,
  onDeleteFailed,
  onDelete,
  onCancel,
  ...props
}) => {
  const { showMessage } = useMessage();

  const deleteCategory = useCallback(async () => {
    if (typeof cohort.id !== 'number') return;
    await ClientAPI.deleteCohort(cohort.id)
      .then(({ status, message, data }) => {
        if (status === 'success' && data) {
          onDelete?.();
          return;
        } else if (status === 'binded_audience' && typeof data === 'object') {
          onDeleteFailed?.(data);
          return;
        }
        showMessage(`Delete cohort failed, ${message}`);
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      });
  }, [cohort.id, showMessage, onDelete, onDeleteFailed]);

  const onBtnDeleteClicked = useCallback(() => {
    deleteCategory();
  }, [deleteCategory]);

  const onBtnCancelClicked = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onBtnCancelClicked?.();
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={onBtnCancelClicked}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack className={classes.root} spacing={1}>
        <Stack spacing={3} className={classes.content}>
          <Stack alignItems='center' spacing={2}>
            <CommonChip>
              <DeleteOutlineOutlined fontSize='large' />
            </CommonChip>
            <Stack spacing={1}>
              <Typography variant='h6' textAlign='center'>
                Delete Cohort
              </Typography>
              <Typography variant='body1' textAlign='center' color={colorPrimary10}>
                Deleting the <span style={{ color: colorSecondary40 }}>"{cohort.name}"</span> is irreversible.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
          <Button variant='outlined' onClick={onBtnCancelClicked}>
            Cancel
          </Button>
          <Button variant='contained' onClick={onBtnDeleteClicked}>
            Delete
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
