import { Breadcrumbs, Button, IconButton, Link, MenuItem, MenuList, Popover, Stack, Typography } from '@mui/material';
import {
  DeleteOutlineOutlined,
  EditOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  MoreVertOutlined,
  NavigateNextOutlined,
} from '@mui/icons-material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { colorNeutralVariant30, colorPrimary30, colorSecondary40 } from 'common/params';
import { datetimeFormatter, numberFormatter } from 'common/formatters';

import ClientAPI from 'common/ClientAPI';
import { CustomizedCategory } from 'common/types/Category';
import { CustomizedCategoryDeleteDialog } from './dialog/CustomizedCategoryDeleteDialog';
import { CustomizedCategoryEditDialog } from './dialog/CustomizedCategoryEditDialog';
import { CustomizedCategoryUploadDialog } from 'components/Category/dialog/CustomizedCategoryUploadDialog';
import { JobStatus } from 'common/types/JobStatus';
import { Spinner } from 'components/common/Spinner';
import classNames from 'classnames';
import classes from './CustomizedCategoryList.module.scss';
import { get } from 'lodash';
import { renderJobStatus } from 'components/JobStatus/renderJobStatus';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

enum FilterStatus {
  ALL = 'All',
  READY = 'Ready',
  SYNCING = 'Syncing',
  FAILED = 'failed',
}

interface CustomizedCategoryListProps {
  onBack?: () => void;
}

export const CustomizedCategoryList: FC<CustomizedCategoryListProps> = ({ onBack }) => {
  const { showMessage } = useMessage();
  const [uploadDialogOpened, setUploadDialogOpened] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [filterStatusValue, setFilterStatusValue] = useState<FilterStatus>(FilterStatus.ALL);
  const [anchorElement, setAnchor] = useState<HTMLElement>();
  const [selectedCategory, setSelectedCategory] = useState<CustomizedCategory | undefined>(undefined);
  const [editDialogOpened, setEditDialogOpened] = useState<boolean>(false);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState<boolean>(false);

  const {
    loading,
    error,
    value: customizedCategories,
  } = useAsync(async () => {
    return (await ClientAPI.getCustomizedCategories()).data;
  }, [refetch]);

  useEffect(() => {
    error && error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  const onCategoryUpload = useCallback(() => {
    setUploadDialogOpened(true);
  }, []);

  const downloadCategory = useCallback(
    async (category: CustomizedCategory) => {
      await ClientAPI.getCustomizedCategoriyFileLink(category.id)
        .then(({ status, data, message }) => {
          if (status === 'success' && data) {
            const link = document.createElement('a');
            link.href = data;
            link.click();
            return;
          }
          showMessage(`Get customized category file link failed, ${message}`);
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    },
    [showMessage],
  );

  const filteredCategories = useMemo(() => {
    return (customizedCategories || [])
      .filter((category) => category.disabled === false)
      .filter((category) => {
        switch (filterStatusValue) {
          case FilterStatus.ALL:
            return true;
          case FilterStatus.READY:
            return category.status === JobStatus.COMPLETED;
          case FilterStatus.SYNCING:
            return category.status === JobStatus.QUEUEING || category.status === JobStatus.RUNNING;
          case FilterStatus.FAILED:
            return category.status === JobStatus.FAILED;
          default:
            return false;
        }
      });
  }, [filterStatusValue, customizedCategories]);

  return (
    <Stack className={classes.root} spacing={4}>
      <Breadcrumbs
        separator={<NavigateNextOutlined style={{ color: colorNeutralVariant30, opacity: 0.38 }} />}
        className={classes.breadcrumbs}
        aria-label='breadcrumb'
      >
        <Link
          variant='subtitle2'
          underline='none'
          color={colorNeutralVariant30}
          className={classes.link}
          onClick={onBack}
        >
          <Typography variant='label1'>File management</Typography>
        </Link>
        <Typography color={colorPrimary30} variant='label1'>
          Self-upload Category
        </Typography>
      </Breadcrumbs>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h3'>Customized category</Typography>
        <Button variant='contained' startIcon={<FileUploadOutlined />} onClick={onCategoryUpload}>
          Upload
        </Button>
      </Stack>
      <Stack direction='row' spacing={3}>
        {Object.values(FilterStatus).map((filterStatus) => (
          <Button
            key={filterStatus}
            className={classNames(classes.filter, filterStatus === filterStatusValue && classes.selected)}
            onClick={() => setFilterStatusValue(filterStatus)}
          >
            {filterStatus}
          </Button>
        ))}
      </Stack>
      {loading ? (
        <Spinner />
      ) : filteredCategories.length ? (
        filteredCategories.map((category) => (
          <Stack key={category.id} className={classes.category} spacing={2}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Stack direction='row' spacing={3} alignItems='center'>
                <Stack direction='row' spacing={1} alignItems='center'>
                  {renderJobStatus(category.status)}
                </Stack>
                {category.error ? (
                  <Typography variant='body2' color='error'>
                    {category.error}
                  </Typography>
                ) : null}
              </Stack>
              <IconButton
                onClick={(event) => {
                  setAnchor(event.currentTarget);
                  setSelectedCategory(category);
                }}
              >
                <MoreVertOutlined />
              </IconButton>
            </Stack>

            <Typography variant='body2'>
              Created on {datetimeFormatter.format((category.created_at || 0) * 1000)}
            </Typography>

            <Typography variant='h6'>{category.name}</Typography>
            {get(category.result_meta, 'wallet_count') ? (
              <Typography variant='body1' color={colorSecondary40}>
                {numberFormatter.format(get(category.result_meta, 'wallet_count', NaN))}
              </Typography>
            ) : null}
            {category.description ? <Typography variant='body2'>{category.description}</Typography> : null}
          </Stack>
        ))
      ) : (
        <Typography>No categories available</Typography>
      )}
      {uploadDialogOpened ? (
        <CustomizedCategoryUploadDialog
          open
          onCreate={() => {
            setUploadDialogOpened(false);
            setRefetch((old) => !old);
          }}
          onCancel={() => setUploadDialogOpened(false)}
        />
      ) : null}
      {editDialogOpened && selectedCategory ? (
        <CustomizedCategoryEditDialog
          open
          category={selectedCategory}
          onSave={() => {
            setSelectedCategory(undefined);
            setEditDialogOpened(false);
            setRefetch((old) => !old);
          }}
          onCancel={() => setEditDialogOpened(false)}
        />
      ) : null}
      {deleteDialogOpened && selectedCategory ? (
        <CustomizedCategoryDeleteDialog
          open
          category={selectedCategory}
          onDelete={() => {
            setSelectedCategory(undefined);
            setDeleteDialogOpened(false);
            setRefetch((old) => !old);
          }}
          onCancel={() => setDeleteDialogOpened(false)}
        />
      ) : null}
      <Popover
        open={anchorElement != null}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchor(undefined);
        }}
      >
        <MenuList>
          <MenuItem
            disabled={!selectedCategory}
            onClick={() => {
              if (!selectedCategory) return;
              setAnchor(undefined);
              setEditDialogOpened(true);
            }}
          >
            <Stack direction='row' spacing={1}>
              <EditOutlined />
              <Typography>Edit</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            disabled={!selectedCategory}
            onClick={() => {
              if (!selectedCategory) return;
              setAnchor(undefined);
              downloadCategory(selectedCategory);
            }}
          >
            <Stack direction='row' spacing={1}>
              <FileDownloadOutlined />
              <Typography>Download</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            disabled={!selectedCategory}
            onClick={() => {
              setAnchor(undefined);
              setDeleteDialogOpened(true);
            }}
          >
            <Stack direction='row' spacing={1} color='error'>
              <DeleteOutlineOutlined color='error' />
              <Typography color='error'>Delete</Typography>
            </Stack>
          </MenuItem>
        </MenuList>
      </Popover>
    </Stack>
  );
};
