import { Button, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { IntegrationAuth, IntegrationAuthProvider } from 'common/types/IntegrationAuth';

import ClientAPI from 'common/ClientAPI';
import { GoogleAnalyticsIcon } from 'components/icons/GoogleAnalyticsIcon';
import { GoogleDriveIcon } from 'components/icons/GoogleDriveIcon';
import { GoogleDriveSettingDialog } from 'components/GoogleDrive/setting/GoogleDriveSettingDialog';
import { TwitterAdsSettingDialog } from 'components/TwitterAds/setting/TwitterAdsSettingDialog';
import { TwitterAuth } from 'common/types/TwitterAds';
import { Uris } from 'Uris';
import { XIcon } from 'components/icons/XIcon';
import classNames from 'classnames';
import classes from './UserIntegration.module.scss';
import { setCookie } from 'common/utils';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router-dom';

export const UserIntegration: FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();

  const [twitterAuth, setTwitterAuth] = useState<TwitterAuth | undefined>(undefined);
  const [googleDriveAuth, setGoogleDriveAuth] = useState<IntegrationAuth | undefined>(undefined);

  const onBtnTwitterIntegrateClick = useCallback(async () => {
    const twitterAuth = await ClientAPI.getDefaultTwitterAuth()
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      });

    if (twitterAuth) {
      // has default auth -> open twitter ads setting dialog
      setTwitterAuth(twitterAuth);
    } else {
      // no default auth -> redirect user to twitter authorization page
      await ClientAPI.integrationOauth1(IntegrationAuthProvider.TWITTER_ADS)
        .then(({ data: oauth_url }) => {
          if (!oauth_url) return;
          setCookie('redirect_url', window.location.href);
          window.location.href = oauth_url;
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    }
  }, [showMessage]);

  const onBtnGAIntegrateClick = useCallback(async () => {
    const GAAuth = await ClientAPI.getDefaultIntegrationAuth(IntegrationAuthProvider.GA)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      });

    if (GAAuth) {
      // navigate to GA integration page and pass auth data via state
      navigate(Uris.Pages.User.Integration.GA, {
        state: { auth: GAAuth },
      });
    } else {
      // no default auth -> redirect user to twitter authorization page
      await ClientAPI.integrationOauth2(IntegrationAuthProvider.GA)
        .then(({ data: oauth_url }) => {
          if (!oauth_url) return;
          setCookie('redirect_url', window.location.href);
          window.location.href = oauth_url;
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    }
  }, [navigate, showMessage]);

  const onBtnGoogleDriveIntegrateClick = useCallback(async () => {
    const googleDriveAuth = await ClientAPI.getDefaultIntegrationAuth(IntegrationAuthProvider.GOOGLE_DRIVE)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      });

    if (googleDriveAuth) {
      // has default auth -> open google drive auth setting dialog
      setGoogleDriveAuth(googleDriveAuth);
    } else {
      // no default auth -> redirect user to google drive authorization page
      await ClientAPI.integrationOauth2(IntegrationAuthProvider.GOOGLE_DRIVE)
        .then(({ data: oauth_url }) => {
          if (!oauth_url) return;
          setCookie('redirect_url', window.location.href);
          window.location.href = oauth_url;
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    }
  }, [showMessage]);

  const onGoogleDriveSettingClose = useCallback(() => {
    setGoogleDriveAuth(undefined);
  }, []);

  const onTwitterAdsSettingClose = useCallback(() => {
    setTwitterAuth(undefined);
  }, []);

  return (
    <Stack className={classes.root}>
      <Stack className={classes.container} spacing={4}>
        <Typography variant='h3'>Integration</Typography>
        <Stack direction='row' spacing={4}>
          <Stack className={classes.card}>
            <Stack
              className={classNames(classes.img, classes.black)}
              direction='row'
              alignItems='center'
              justifyContent='center'
            >
              <XIcon className={classes.icon} />
            </Stack>
            <Stack className={classes.content} spacing={3}>
              <Typography variant='h4'>Twitter</Typography>
              <Typography variant='body1' className={classes.description}>
                Maximize Your Marketing Efforts: Publish Ads Audience to Twitter Ads
              </Typography>
              <Stack direction='row-reverse'>
                <Button variant='contained' onClick={onBtnTwitterIntegrateClick}>
                  Edit
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <Stack className={classes.card}>
            <Stack className={classes.img} direction='row' alignItems='center' justifyContent='center'>
              <GoogleAnalyticsIcon className={classes.icon} />
            </Stack>
            <Stack className={classes.content} spacing={3}>
              <Typography variant='h4'>Google Analytics</Typography>
              <Typography variant='body1' className={classes.description}>
                Connect your project to Google Analytics account
              </Typography>
              <Stack direction='row-reverse'>
                <Button variant='contained' onClick={onBtnGAIntegrateClick}>
                  Manage
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <Stack className={classes.card}>
            <Stack className={classes.img} direction='row' alignItems='center' justifyContent='center'>
              <GoogleDriveIcon className={classes.icon} />
            </Stack>
            <Stack className={classes.content} spacing={3}>
              <Typography variant='h4'>Google Drive</Typography>
              <Typography variant='body1' className={classes.description}>
                Connect your project to Google Drive account
              </Typography>
              <Stack direction='row-reverse'>
                <Button variant='contained' onClick={onBtnGoogleDriveIntegrateClick}>
                  Manage
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {twitterAuth && (
        <TwitterAdsSettingDialog open defaultAuth={twitterAuth} onDialogClose={onTwitterAdsSettingClose} />
      )}

      {googleDriveAuth && (
        <GoogleDriveSettingDialog open defaultAuth={googleDriveAuth} onDialogClose={onGoogleDriveSettingClose} />
      )}
    </Stack>
  );
};
