import { ArrowBackOutlined, Check } from '@mui/icons-material';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';

import AccountsAPI from 'common/AccountsAPI';
import { Config } from 'config';
import { FireIcon } from 'components/icons/FireIcon';
import { RoundStarIcon } from 'components/icons/RoundStarIcon';
import { Spinner } from 'components/common/Spinner';
import { SubscribeFailedDialog } from 'components/Auth/SubscribeFailedDialog';
import { SubscriptionPlan } from 'common/types/Accounts/SubscriptionPlan';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './SubscriptionPlanList.module.scss';
import { getCurrencySymbol } from 'common/utils';
import { numberFormatter } from 'common/formatters';
import { useAccountsUserProfile } from 'common/hooks/Accounts/useAccountsUserProfile';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

export interface SubscriptionPlanListProps {
  monthly: boolean;
}

export const SubscriptionPlanList: FC<SubscriptionPlanListProps> = ({ monthly }) => {
  const { showMessage } = useMessage();
  const { value: accountsUser } = useAccountsUserProfile();
  const [subscribeError, setSubscribeError] = useState<string>('');
  const [upgrading, setUpgrading] = useState<boolean>(false);
  const { loading, value: subscriptionPlans } = useAsync(async () => {
    return (await AccountsAPI.getSubscriptionPlans()).data;
  }, []);

  const filteredSubscriptionPlans = useMemo(() => {
    return (subscriptionPlans || []).filter((subscriptionPlan) =>
      monthly ? subscriptionPlan.duration_months === 1 : subscriptionPlan.duration_months !== 1,
    );
  }, [monthly, subscriptionPlans]);

  const createSubscriptionPlanCheckout = useCallback(
    async (plan: SubscriptionPlan) => {
      if (upgrading) return;
      setUpgrading(true);
      document.cookie = `redirect_url=${window.location.href};domain=${Config.CookieDomain};path=/`;
      await AccountsAPI.createSubscriptionCheckout(plan, Uris.AccountsApi.User.Checkout.Callback)
        .then(({ message, status, data }) => {
          if (status === 'success' && data?.url) {
            window.location.href = data.url;
            return;
          } else if (status === 'forbidden') {
            setSubscribeError(message || '');
            return;
          }
          showMessage(`Create checkout failed: ${message}`);
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        })
        .finally(() => {
          setUpgrading(false);
        });
    },
    [upgrading, showMessage],
  );

  const getAdditionalBenefit = useCallback((subscriptionPlan: SubscriptionPlan) => {
    let str = 'Everything in ';
    const renderElement = (desc: string) => {
      return (
        <Stack direction='row' alignItems='flex-start' spacing={1}>
          <ArrowBackOutlined className={classes.icon} />
          <Typography variant='body2'>{desc}</Typography>
        </Stack>
      );
    };
    switch (subscriptionPlan.name) {
      case 'Base':
        return null;
      case 'Plus':
        str += 'Base, and';
        return renderElement(str);
      case 'Premium':
        str += 'Plus, and';
        return renderElement(str);
      default:
        return null;
    }
  }, []);

  if (loading) return <Spinner />;

  return (
    <Stack className={classes.root}>
      {!accountsUser?.subscription && accountsUser?.trial?.plan_id === 0 ? (
        <Stack className={classes.grid}>
          <Stack className={classes.subscription} spacing={1.5}>
            <Stack>
              <Typography variant='h4'>Free Trial</Typography>
              <Typography variant='body2'>Free 7-day trial for new friends</Typography>
            </Stack>
            <Stack spacing={1}>
              <Stack direction='row' alignItems='flex-end' spacing={1}>
                <Typography variant='h3' className={classes.price}>
                  $0
                </Typography>
                <Typography variant='body2'>/month</Typography>
              </Stack>
              <Typography variant='subtitle2' className={classes.duration}>
                Billed {monthly ? 'monthly' : 'annually'}
              </Typography>
              <Stack direction='row'>
                <Button variant='contained' disabled>
                  <Typography>Current</Typography>
                </Button>
              </Stack>
            </Stack>
            <Divider />
            <Stack spacing={1.5} className={classes.benefits}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <RoundStarIcon className={classes.icon} />
                <Typography variant='body2'>200 credits for 7 days</Typography>
              </Stack>
              <Stack direction='row' alignItems='flex-start' spacing={1}>
                <Check className={classNames(classes.icon, classes.check)} />
                <Typography variant='body2' className={classes.benefit}>
                  Up to 1 lists in influencers matcher
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='flex-start' spacing={1}>
                <Check className={classNames(classes.icon, classes.check)} />
                <Typography variant='body2' className={classes.benefit}>
                  Basic search features on influencers/projects (Incl. Tweets mentioned/followers/followings)
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : null}
      {filteredSubscriptionPlans.map((subscriptionPlan) => (
        <Stack key={subscriptionPlan.id} className={classes.grid}>
          <Stack className={classes.subscription} spacing={1.5}>
            <Stack>
              <Typography variant='h4'>{subscriptionPlan.name}</Typography>
              <Typography variant='body2'>{subscriptionPlan.description}</Typography>
            </Stack>
            <Stack spacing={1}>
              <Stack direction='row' alignItems='flex-end' spacing={1}>
                <Typography variant='h3' className={classes.price}>
                  {getCurrencySymbol(subscriptionPlan.currency)}
                  {numberFormatter.format(Math.round(subscriptionPlan.price / subscriptionPlan.duration_months))}
                </Typography>
                <Typography variant='body2'>/month</Typography>
              </Stack>
              <Typography variant='subtitle2' className={classes.duration}>
                Billed{' '}
                {monthly
                  ? 'monthly'
                  : `annually (${getCurrencySymbol(subscriptionPlan.currency)}${numberFormatter.format(
                      subscriptionPlan.price,
                    )})`}
              </Typography>
              <Stack direction='row'>
                <Button
                  variant='contained'
                  disabled={upgrading || accountsUser?.subscription?.plan_id === subscriptionPlan.id}
                  onClick={() => {
                    if (subscriptionPlan.name === 'Premium') {
                      window.open(Uris.External.BookDemo, '_blank');
                    } else {
                      createSubscriptionPlanCheckout(subscriptionPlan);
                    }
                  }}
                >
                  <Typography>
                    {accountsUser?.subscription?.plan_id === subscriptionPlan.id
                      ? 'Current Plan'
                      : subscriptionPlan.name === 'Premium'
                      ? 'Schedule a demo'
                      : 'Subscribe'}
                  </Typography>
                </Button>
              </Stack>
            </Stack>
            <Divider />
            <Stack spacing={1.5} className={classes.benefits}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <RoundStarIcon className={classes.icon} />
                <Typography variant='body2'>{subscriptionPlan.credit_per_month} credits /month</Typography>
              </Stack>
              {getAdditionalBenefit(subscriptionPlan)}
              {subscriptionPlan.benefits.map((benefit, idx) => (
                <Stack key={idx} direction='row' alignItems='flex-start' spacing={1}>
                  <Check className={classNames(classes.icon, classes.check)} />
                  <Typography variant='body2' className={classes.benefit}>
                    {benefit}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            {subscriptionPlan.name === 'Plus' ? (
              <Stack className={classes.popular} direction='row' alignItems='center' spacing={1}>
                <FireIcon fontSize='small' />
                <Typography variant='body2'>Most popular</Typography>
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      ))}
      {subscribeError ? (
        <SubscribeFailedDialog open error={subscribeError} onBtnCancelClicked={() => setSubscribeError('')} />
      ) : null}
    </Stack>
  );
};
