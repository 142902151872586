import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Audience } from 'common/types/Audience';
import { AudienceDeleteDialog } from 'components/Audience/dialog/AudienceDeleteDialog';
import { AudienceList } from 'pages/AdsAudience/Audience/AudienceList';
import ClientAPI from 'common/ClientAPI';
import { IntegrationAuthProvider } from 'common/types/IntegrationAuth';
import { Spinner } from 'components/common/Spinner';
import { TwitterAdsPublishDialog } from 'components/TwitterAds/publish/TwitterAdsPublishDialog';
import { Uris } from 'Uris';
import classes from 'pages/AdsAudience/AdsAudience.module.scss';
import { setCookie } from 'common/utils';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router';
import { useTracking } from 'common/hooks/useTracking';

export const AdsAudience = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const [audiences, setAudiences] = useState<Audience[]>([]);
  const [selectedAudience, setSelectedAudience] = useState<Audience | undefined>(undefined);
  const [twitterAdsAccountId, setTwitterAdsAccountId] = useState<string | undefined>(undefined);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [deletedAudience, setDeletedAudience] = useState<Audience | undefined>(undefined);

  const { loading, error, value } = useAsync(async () => {
    return (await ClientAPI.getAudiences()).data;
  }, [refetch]);

  useEffect(() => {
    if (!value) return;
    setAudiences(value);
  }, [value]);

  useEffect(() => {
    error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  const onAudiencePublish = useCallback(
    async (publishAudience: Audience) => {
      const audience = audiences.find((audience) => audience.id === publishAudience.id);
      if (!audience) return;

      const twitterAuth = await ClientAPI.getDefaultTwitterAuth()
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });

      if (twitterAuth) {
        // has default auth -> try to get default twitter ads account id
        const account = await ClientAPI.getDefaultTwitterAdsAccount()
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
          });
        if (account) {
          setTwitterAdsAccountId(account.id);
        }
        setSelectedAudience(audience);
      } else {
        // no default auth -> redirect user to twitter authorization page
        await ClientAPI.integrationOauth1(IntegrationAuthProvider.TWITTER_ADS)
          .then(({ data: oauth_url }) => {
            if (!oauth_url) return;
            setCookie('redirect_url', window.location.href);
            window.location.href = oauth_url;
          })
          .catch((error) => {
            showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
          });
      }
    },
    [showMessage, audiences],
  );

  const onAudienceDelete = useCallback(async (audience: Audience) => {
    setDeletedAudience(audience);
  }, []);

  const onAudienceDuplicate = useCallback(
    (audience: Audience) => {
      // navigate to create audience page and pass audience data via state
      track('form_start', { sub_event: 'audience_add_started' });
      navigate(Uris.Pages.AdsAudience.CreateAudience, {
        state: { audience: audiences.find((a) => a.id === audience.id) },
      });
    },
    [audiences, navigate, track],
  );

  const onTwitterAdsPublishDialogClose = useCallback(() => {
    setSelectedAudience(undefined);
  }, []);

  return (
    <Stack className={classes.root}>
      <Stack className={classes.top}>
        <Stack className={classes.content}>
          <Typography variant='h2'>Ads Audience</Typography>
          <Typography className={classes.subText}>
            Leverage Cohort-based Ads Audience to drive high-performance campaigns.
          </Typography>
        </Stack>
      </Stack>
      <Stack className={classes.bottom} spacing={3}>
        <Stack direction='row' className={classes.title}>
          <Typography variant='h3'>Audience Lists</Typography>
          <Button
            id='btn-create-ads-audience'
            variant='contained'
            startIcon={<Add />}
            onClick={() => navigate(Uris.Pages.AdsAudience.CreateAudience)}
          >
            New Audience
          </Button>
        </Stack>
        {loading ? (
          <Spinner />
        ) : audiences.length ? (
          <AudienceList
            className={classes.table}
            audiences={audiences}
            onPublish={onAudiencePublish}
            onDuplicate={onAudienceDuplicate}
            onDelete={onAudienceDelete}
          />
        ) : (
          <Stack className={classes.init} spacing={3}>
            <Typography variant='h3'>Take the first step</Typography>
            <Typography variant='h6'>
              In this process, we aim to bridge the gap between potential clients on-chain and off-chain to optimize ad
              targeting.
            </Typography>
            <Button
              className={classes.btn}
              variant='contained'
              onClick={() => navigate(Uris.Pages.AdsAudience.CreateAudience)}
            >
              Getting Started
            </Button>
          </Stack>
        )}
      </Stack>

      {/* delete audience -> show confirm dialog */}
      {deletedAudience ? (
        <AudienceDeleteDialog
          open
          audience={deletedAudience}
          onDelete={() => {
            setDeletedAudience(undefined);
            setRefetch((old) => !old);
          }}
          onCancel={() => {
            setDeletedAudience(undefined);
          }}
        />
      ) : null}

      {/* in this way, every time selectedAudience is set to undefined, 
          TwitterAdsPublishDialog will unmount and all params inside it will be reset */}
      {selectedAudience && (
        <TwitterAdsPublishDialog
          open
          audience={selectedAudience}
          accountId={twitterAdsAccountId}
          onDialogClose={onTwitterAdsPublishDialogClose}
        />
      )}
    </Stack>
  );
};
