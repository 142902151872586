import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import {
  DefaultInfluencerCampaign,
  InfluencerCampaign,
  InfluencerCampaignStatus,
} from 'common/types/Extension/InfluencerCampaign';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { AccountsContext } from 'contexts/AccountsContext';
import { ArrowBackOutlined } from '@mui/icons-material';
import { CampaignCookieEdit } from 'components/InfluencerCampaign/Edit/CampaignCookieEdit';
import { CampaignInfoEdit } from 'components/InfluencerCampaign/Edit/CampaignInfoEdit';
import { CampaignLaunch } from 'components/InfluencerCampaign/Edit/CampaignLaunch';
import { CampaignMessageEdit } from 'components/InfluencerCampaign/Edit/CampaignMessageEdit';
import { CampaignThreadListEdit } from 'components/InfluencerCampaign/Edit/CampaignThreadListEdit';
import ExtensionAPI from 'common/ExtensionAPI';
import { TimeEstimateDialog } from 'components/InfluencerCampaign/Edit/Dialog/TimeEstimateDialog';
import { Uris } from 'Uris';
import classes from './CreateCampaign.module.scss';
import { useLeavePageConfirm } from 'common/hooks/useLeavePageConfirm';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'common/hooks/useTracking';

const tabs: string[] = ['1. Basic Info', '2. Add Influencers', '3. Messages', '4. Connect Account', '5. Launch'];

export const CreateCampaign: FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const { refetchUser$ } = useContext(AccountsContext);
  const [step, setStep] = useState<number>(0);
  const [saving, setSaving] = useState<boolean>(false);
  const [editCampaign, setEditCampaign] = useState<InfluencerCampaign>(DefaultInfluencerCampaign);

  const [estimateDialogOpened, setEstimateDialogOpened] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [cookieName, setCookieName] = useState<string | undefined>(undefined);

  /** Window close with edited content not saved */
  useLeavePageConfirm(!saveSuccess); // when success, disable leave page confirm

  useEffect(() => {
    if (!saveSuccess) return;
    setSaveSuccess(false);
    navigate(Uris.Pages.InfluencerMatcher.Index, { state: { tab: 'Campaign' } });
    refetchUser$.next(true);
  }, [saveSuccess, refetchUser$, navigate]);

  const handleStepChange = useCallback((_: React.SyntheticEvent, newStep: number) => {
    setStep(newStep);
  }, []);

  const saveCampaign = useCallback(
    async (send?: boolean) => {
      setSaving(true);
      await ExtensionAPI.createInfluencerCampaign({
        ...editCampaign,
        status: send ? InfluencerCampaignStatus.IN_PROGRESS : InfluencerCampaignStatus.DRAFT,
      })
        .then(({ status, data }) => {
          if (status === 'success' && data) {
            track('form_submit', {
              sub_event: 'campaign_created',
              custom_props: {
                campaign_id: data.id,
                campaign_name: editCampaign.name,
                cookie_name: cookieName,
                message_count: editCampaign.messages?.length,
                influencer_count: editCampaign.threads?.length,
              },
            });
            if (send) {
              setEstimateDialogOpened(true);
              return;
            }
            setSaveSuccess(true);
            return;
          }
          showMessage(`Create campaign failed: ${status}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [editCampaign, showMessage, cookieName, track],
  );

  const content = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <CampaignInfoEdit
            campaign={editCampaign}
            onCampaignInfoEdit={(campaign) => setEditCampaign(campaign)}
            onNextBtnClicked={() => setStep((old) => old + 1)}
          />
        );
      case 1:
        return (
          <CampaignThreadListEdit
            draft={true}
            saving={saving}
            threads={editCampaign.threads || []}
            excludedThreads={[]}
            onCampaignThreadListEdit={(threads) => setEditCampaign((old) => ({ ...old, threads: threads }))}
            onBackBtnClicked={() => setStep((old) => old - 1)}
            onNextBtnClicked={() => setStep((old) => old + 1)}
            onSaveBtnClicked={() => {
              saveCampaign();
            }}
          />
        );
      case 2:
        return (
          <CampaignMessageEdit
            draft={true}
            saving={saving}
            threads={editCampaign.threads || []}
            messages={editCampaign.messages || []}
            readonlyMessages={[]}
            onCampaignThreadEdit={(threads) => setEditCampaign((old) => ({ ...old, threads: threads }))}
            onCampaignMessageEdit={(messages) => setEditCampaign((old) => ({ ...old, messages: messages }))}
            onBackBtnClicked={() => setStep((old) => old - 1)}
            onNextBtnClicked={() => setStep((old) => old + 1)}
            onSaveBtnClicked={() => {
              saveCampaign();
            }}
          />
        );
      case 3:
        return (
          <CampaignCookieEdit
            draft={true}
            saving={saving}
            onCampaignCookieEdit={(cookie) => {
              setCookieName(cookie.username);
              setEditCampaign((old) => ({
                ...old,
                threads: old.threads?.map((thread) => ({
                  ...thread,
                  integration_auth_id: cookie.id,
                  sender_id: cookie.user_id,
                })),
              }));
            }}
            onBackBtnClicked={() => setStep((old) => old - 1)}
            onNextBtnClicked={() => setStep((old) => old + 1)}
            onSaveBtnClicked={() => {
              saveCampaign();
            }}
          />
        );
      case 4:
        return (
          <CampaignLaunch
            draft={true}
            saving={saving}
            campaign={editCampaign}
            onSendBtnClicked={() => {
              saveCampaign(true);
            }}
            onBackBtnClicked={() => setStep((old) => old - 1)}
            onSaveBtnClicked={() => {
              saveCampaign();
            }}
          ></CampaignLaunch>
        );
    }
  }, [step, saving, editCampaign, saveCampaign]);

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack direction='row'>
        <Button startIcon={<ArrowBackOutlined />} onClick={() => navigate(-1)}>
          Back to list
        </Button>
      </Stack>
      <Typography variant='h3'>Create Campaign</Typography>
      <Tabs value={step} onChange={handleStepChange} centered className={classes.tabs}>
        {tabs.map((tab, idx) => (
          <Tab
            key={tab}
            className={classes.tab}
            style={{ width: `${100 / tabs.length}%` }}
            disabled={idx > step}
            label={
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='subtitle2'>{tab}</Typography>
              </Stack>
            }
          />
        ))}
      </Tabs>
      {content}
      {estimateDialogOpened ? (
        <TimeEstimateDialog open campaign={editCampaign} onBack={() => setSaveSuccess(true)} />
      ) : null}
    </Stack>
  );
};
