import { Button, Stack, Typography } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  InfluencerCampaign,
  InfluencerCampaignStatus,
  InfluencerCampaignStatusNameMap,
} from 'common/types/Extension/InfluencerCampaign';
import { colorNeutralVariant50, colorPrimary40, colorSurface1 } from 'common/params';

import { AddOutlined } from '@mui/icons-material';
import { ArchiveCampaignDialog } from './Edit/Dialog/ArchiveCampaignDialog';
import ExtensionAPI from 'common/ExtensionAPI';
import { ExtensionContext } from 'contexts/ExtensionContext';
import { InfluencerCampaignList } from './InfluencerCampaignList';
import { Uris } from 'Uris';
import classes from './InfluencerCampaignOverview.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'common/hooks/useTracking';

interface InfluencerCampaignOverviewProps {
  onInfluencerCampaignViewClicked?: (influencerCampaign: InfluencerCampaign) => void;
}

export const InfluencerCampaignOverview: FC<InfluencerCampaignOverviewProps> = ({
  onInfluencerCampaignViewClicked,
}) => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const { selectedCampaignStatus$ } = useContext(ExtensionContext);
  const [archiveCampaign, setArchiveCampaign] = useState<InfluencerCampaign | undefined>(undefined);
  const [selectedStatus, setSelectedStatus] = useState<InfluencerCampaignStatus>(
    selectedCampaignStatus$.getValue() || InfluencerCampaignStatus.DRAFT,
  );
  const [refetch, setRefetch] = useState<boolean>(false);

  useEffect(() => {
    selectedCampaignStatus$.next(selectedStatus);
  }, [selectedStatus, selectedCampaignStatus$]);

  const { value: campaigns } = useAsync(async () => {
    return (await ExtensionAPI.getInfluencerCampaigns()).data;
  }, [refetch]);

  const filteredCampaigns = useMemo(() => {
    if (!selectedStatus) return campaigns || [];
    return (campaigns || []).filter((campaign) => campaign.status === selectedStatus);
  }, [selectedStatus, campaigns]);

  const onInfluencerCampaignEditClicked = useCallback(
    (campaign: InfluencerCampaign) => {
      navigate(
        Uris.Pages.InfluencerMatcher.EditInfluencerCampaign.replace(':campaignId', campaign.id?.toString() || ''),
      );
    },
    [navigate],
  );

  const onInfluencerCampaignPinClicked = useCallback(
    async (campaign: InfluencerCampaign) => {
      await ExtensionAPI.updateInfluencerCampaignBasicInfo(campaign)
        .then(({ status, data }) => {
          if (status === 'success' && data) {
            setRefetch((old) => !old);
            return;
          }
          showMessage(`Update campaign failed: ${status}`, 'error');
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    },
    [showMessage],
  );

  const onInfluencerCampaignArchiveClicked = useCallback(async (campaign: InfluencerCampaign) => {
    setArchiveCampaign(campaign);
  }, []);

  return (
    <Stack className={classes.root} spacing={3}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h3'>Campaigns</Typography>
        <Button
          id='btn-influencer-matcher-create-campaign'
          variant='contained'
          startIcon={<AddOutlined />}
          onClick={() => {
            track('form_start', { sub_event: 'campaign_add_started' });
            navigate(Uris.Pages.InfluencerMatcher.CreateInfluencerCampaign);
          }}
        >
          New Campaign
        </Button>
      </Stack>
      <Stack direction='row'>
        <Stack direction='row' spacing={1} id='area-campaign-list-filter-btn'>
          {Array.from(InfluencerCampaignStatusNameMap.entries()).map((entry) => (
            <Button
              key={entry[0]}
              className={classes.filterBtn}
              sx={{
                '&.MuiButton-contained': {
                  backgroundColor: colorSurface1,
                  color: colorPrimary40,
                  border: `1px solid ${colorNeutralVariant50}`,
                },
              }}
              variant={selectedStatus === entry[0] ? 'contained' : 'outlined'}
              onClick={() => setSelectedStatus(entry[0])}
            >
              {entry[1]} - {(campaigns || []).filter((campaign) => campaign.status === entry[0]).length}
            </Button>
          ))}
        </Stack>
      </Stack>
      <Stack className={classes.table} alignItems='center'>
        {(campaigns || []).length ? (
          <InfluencerCampaignList
            status={selectedStatus}
            campaigns={filteredCampaigns}
            onInfluencerCampaignEditClicked={onInfluencerCampaignEditClicked}
            onInfluencerCampaignViewClicked={onInfluencerCampaignViewClicked}
            onInfluencerCampaignPinClicked={onInfluencerCampaignPinClicked}
            onInfluencerCampaignArchiveClicked={onInfluencerCampaignArchiveClicked}
          />
        ) : (
          <Stack spacing={3} className={classes.empty} alignItems='center'>
            <Typography variant='h4'>Create Your First Campaign</Typography>
            <Typography variant='h6'>Take the first step towards better ad targeting</Typography>
          </Stack>
        )}
      </Stack>

      {archiveCampaign ? (
        <ArchiveCampaignDialog
          open
          campaign={archiveCampaign}
          onArchive={() => {
            setArchiveCampaign(undefined);
            setRefetch((old) => !old);
          }}
          onCancel={() => setArchiveCampaign(undefined)}
        />
      ) : null}
    </Stack>
  );
};
