import { TwitterUserMeta } from 'common/types/Extension/TwitterUser';
import { v4 as uuidv4 } from 'uuid';

export enum InfluencerCampaignStatus {
  DRAFT = 'draft',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

export const InfluencerCampaignStatusNameMap = new Map<InfluencerCampaignStatus, string>([
  [InfluencerCampaignStatus.DRAFT, 'Draft'],
  [InfluencerCampaignStatus.IN_PROGRESS, 'In Progress'],
  [InfluencerCampaignStatus.DONE, 'Done'],
]);

export enum InfluencerThreadStage {
  INVITED = 'invited',
  REPLIED = 'replied',
  ACCEPTED = 'accepted',
  OFFERED = 'offered',
  PUBLISHED = 'published',
}

export const InfluencerThreadStageNameMap = new Map<InfluencerThreadStage, string>([
  [InfluencerThreadStage.INVITED, 'Invitation'],
  [InfluencerThreadStage.REPLIED, 'Replied'],
  [InfluencerThreadStage.ACCEPTED, 'Accpeted'],
  [InfluencerThreadStage.OFFERED, 'Offered'],
  [InfluencerThreadStage.PUBLISHED, 'Published'],
]);

export enum InfluencerThreadStatus {
  INIT = 'init',
  SENDING = 'sending',
  SENT = 'sent',
  FAILED = 'failed',
}

export const InfluencerThreadStatusNameMap = new Map<InfluencerThreadStatus, string>([
  [InfluencerThreadStatus.INIT, 'No Message'],
  [InfluencerThreadStatus.SENDING, 'Sending'],
  [InfluencerThreadStatus.SENT, 'Sent'],
  [InfluencerThreadStatus.FAILED, 'Fail'],
]);

export interface InfluencerCampaign {
  id?: number;
  name: string;
  description?: string;
  status: InfluencerCampaignStatus;
  pinned: boolean;

  threads?: InfluencerThread[];
  messages?: InfluencerMessage[];
  metrics?: InfluencerCampaignMetrics;
  created_at?: number;
  updated_at?: number;
}

export interface InfluencerCampaignMetrics {
  invited_count?: number;
  replied_count?: number;
  accepted_count?: number;
  offered_count?: number;
  published_count?: number;
}

export interface InfluencerThread {
  id: string;
  influencer_campaign_id: number;
  receiver_id: string;
  integration_auth_id?: string;
  sender_id?: string;
  influencer_message_id?: string;
  stage: InfluencerThreadStage;
  status: InfluencerThreadStatus;
  error_msg?: string;

  created_at?: number;
  updated_at?: number;

  // twitter user meta
  receiver?: TwitterUserMeta;
}

export interface InfluencerMessage {
  id: string;
  content: string;
  is_template?: boolean;

  created_at?: number;
  updated_at?: number;

  sent_messages?: InfluencerSentMessage[];
}

export interface InfluencerSentMessage {
  influencer_message_id: string;
  influencer_campaign_id: number;
  receiver_id: string;
  stage: string;

  id?: number;
  created_at?: number;
}

export interface InfluencerPostLink {
  name: string;
  link: string;
  influencer_campaign_id: number;
  twitter_user_id: string;

  id?: number;
  created_at?: number;
  updated_at?: number;
}

export const DefaultInfluencerCampaign: InfluencerCampaign = {
  name: '',
  status: InfluencerCampaignStatus.DRAFT,
  pinned: false,
};

export const DefaultInfluencerThread: InfluencerThread = {
  id: uuidv4(),
  influencer_campaign_id: 0,
  receiver_id: '',
  stage: InfluencerThreadStage.INVITED,
  status: InfluencerThreadStatus.INIT,
};

export const DefaultInfluencerMessage: InfluencerMessage = {
  id: uuidv4(),
  content: '',
};

export const DefaultInfluencerPostLink: InfluencerPostLink = {
  name: '',
  link: '',
  influencer_campaign_id: -1,
  twitter_user_id: '',
};

export interface TwitterCookie {
  id?: string;
  username: string;
  user_id: string;
  auth_token: string;
  ct0: string;
}

export const calculateEstimateTime = (campaign: InfluencerCampaign) => {
  const sendingThreads = (campaign.threads || []).filter((thread) => thread.status === InfluencerThreadStatus.SENDING);
  if (sendingThreads.length === 0) return 0;
  return sendingThreads.length * 1.5 + 10;
};
