import { useCallback, useContext, useEffect, useState } from 'react';

import ClientAPI from 'common/ClientAPI';
import { DataContext } from 'contexts/DataContext';
import { User } from 'common/types/User';
import { useMessage } from 'components/message/useMessage';

export const useUserProfile = () => {
  const { showMessage } = useMessage();
  const { user$ } = useContext(DataContext);
  const [user, setUser] = useState<User | undefined>(user$.getValue());
  const [loading, setLoading] = useState<boolean>(true);

  const fetchUser = useCallback(async () => {
    setLoading(true);
    await ClientAPI.getUserProfile()
      .then(({ data }) => {
        if (data) {
          user$.next(data);
          setUser(data);
        }
      })
      .catch((error) => {
        const statusCode = error?.cause || 0;
        if (statusCode !== 404) {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showMessage, user$]);

  useEffect(() => {
    if (user) {
      setLoading(false);
      return;
    }
    fetchUser();
  }, [user, fetchUser]);

  return { loading, value: user };
};
