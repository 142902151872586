import { FC, HTMLAttributes, useCallback, useContext, useEffect, useState } from 'react';
import {
  Tutorial,
  TutorialCompleteAction,
  TutorialCompleteActionBtn,
  TutorialStep,
  matchUrl,
  tutorials,
} from 'common/types/Tutorial';
import { useLifecycles, useObservable } from 'react-use';
import { useLocation, useNavigate } from 'react-router-dom';

import ClientAPI from 'common/ClientAPI';
import { TutorialCompleteDialog } from './TutorialCompleteDialog';
import { TutorialContext } from 'contexts/TutorialContext';
import { TutorialGetStarted } from './TutorialGetStarted';
import { TutorialHighlight } from './TutorialHighlight';
import { TutorialHint } from './TutorialHint';
import { TutorialLandingDialog } from './TutorialLandingDialog';
import { TutorialRewardDialog } from './TutorialRewardDialog';
import { Uris } from 'Uris';
import { useCompletedTutorials } from 'common/hooks/useCompletedTutorials';
import { useMessage } from 'components/message/useMessage';

export const TutorialProvider: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const { component$, tutorial$, refetchCompletedTutorials$, landingCompleted$ } = useContext(TutorialContext);
  const { pathname } = useLocation();

  const { value: completedTutorials } = useCompletedTutorials();

  const component = useObservable(component$);
  const tutorial = useObservable(tutorial$);
  const landingCompleted = useObservable(landingCompleted$, true);

  const [showHint, setShowHint] = useState<boolean>(true);
  const [tutorialStep, setTutorialStep] = useState<TutorialStep | undefined>(undefined);
  const [completeAction, setCompleteAction] = useState<TutorialCompleteAction | undefined>(undefined);
  const [skipTutorial, setSkipTutorial] = useState<boolean>(window.sessionStorage.getItem(`skip-tutorial`) === 'true');
  const [rewardOpened, setRewardOpened] = useState<boolean>(false);

  useLifecycles(
    () => {},
    () => {
      component$.next(undefined);
    },
  );

  const scrollIntoViewAndWait = useCallback((element: Element) => {
    element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    // check that scroll animation is end and resolve promise
    return new Promise((resolve) => {
      const threshold = 5;
      let counter = 0;
      let prevRect = element.getBoundingClientRect();
      // element position not changed for continuous 5 frame -> resolve promise
      const checkStop = () => {
        const rect = element.getBoundingClientRect();
        if (
          rect.top === prevRect.top &&
          rect.left === prevRect.left &&
          rect.bottom === prevRect.bottom &&
          rect.right === prevRect.right
        ) {
          counter++;
        } else {
          counter = 0;
          prevRect = rect;
        }
        if (counter > threshold) {
          resolve(undefined);
          return;
        }
        requestAnimationFrame(checkStop);
      };
      requestAnimationFrame(checkStop);

      // avoid infinite loop
      setTimeout(() => resolve(undefined), 1000);
    });
  }, []);

  const onTutorialFinished = useCallback(
    async (tutorial: Tutorial, reset = true) => {
      await ClientAPI.completeTutorial(tutorial)
        .then(({ status, message, data }) => {
          if (status === 'success') {
            refetchCompletedTutorials$.next(true);
            setCompleteAction(tutorial.completeAction);
            if (reset) {
              setTutorialStep(undefined);
            }
            // has coupon hash send back -> open reward dialog
            if (data) {
              setRewardOpened(true);
            }
            return;
          }
          showMessage(`Get completed tutorials failed, ${message}`);
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        });
    },
    [refetchCompletedTutorials$, showMessage],
  );

  // if skip tutorial -> reset all params
  useEffect(() => {
    if (!skipTutorial) return;
    setShowHint(false);
    component$.next(undefined);
    tutorial$.next(undefined);
    setTutorialStep(undefined);
    setCompleteAction(undefined);
  }, [skipTutorial, tutorial$, component$]);

  // check whether tutorial has steps to process
  useEffect(() => {
    if (!landingCompleted) return; // not complete landing -> directly return
    if (!tutorial) return;
    if (!tutorial.steps.length) {
      // no steps -> directly mark as finished
      onTutorialFinished(tutorial);
    }
    setTutorialStep(tutorial.steps.length ? tutorial.steps[0] : undefined);
  }, [landingCompleted, tutorial, onTutorialFinished]);

  // detecting whether user leave tutorial step url
  useEffect(() => {
    if (skipTutorial) return;
    if (!tutorial) return;
    if (!tutorialStep) return;
    const prevStepIdx = tutorial.steps.findIndex((t) => t.key === tutorialStep.key) - 1;
    if (prevStepIdx < 0) return;
    if (matchUrl(pathname, tutorial.steps[prevStepIdx].url) || matchUrl(pathname, tutorialStep.url)) return;
    setTutorialStep(tutorial.steps.length ? tutorial.steps[0] : undefined);
  }, [pathname, skipTutorial, tutorial, tutorialStep]);

  // detecting whether target element appear in current window
  useEffect(() => {
    if (skipTutorial) return;
    if (!tutorialStep) return;
    if (!matchUrl(pathname, tutorialStep.url)) return;

    const findElement = async () => {
      const element = document.querySelector(`#${tutorialStep.elementId}`);
      if (!element) return;

      await scrollIntoViewAndWait(element).then(() => {
        component$.next(element);
        setShowHint(true);
      });

      clearInterval(internal);
    };

    const internal = setInterval(() => {
      findElement();
    }, 1000);

    findElement();
    return () => {
      clearInterval(internal);
    };
  }, [skipTutorial, pathname, tutorialStep, component$, scrollIntoViewAndWait]);

  const onNextClick = useCallback(
    (tutorialStep: TutorialStep) => {
      if (!tutorial) return;
      if (!component) return;

      const switchToNextStep = () => {
        const idx = tutorial.steps.findIndex((step) => step.key === tutorialStep.key);
        if (typeof idx !== 'number') return;

        // check this tutorial is finished or not
        if (idx >= tutorial.steps.length - 1) {
          onTutorialFinished(tutorial);
          return;
        }

        setTutorialStep(tutorial.steps[idx + 1]);
        if (tutorialStep.waitAction) component.removeEventListener('click', switchToNextStep);
      };

      if (tutorialStep.waitAction) {
        component.addEventListener('click', switchToNextStep);
      } else {
        switchToNextStep();
      }
      setShowHint(false);
    },
    [component, tutorial, onTutorialFinished],
  );

  const onActionBtnClick = useCallback(
    (actionBtn: TutorialCompleteActionBtn) => {
      if (actionBtn.nextTutorialKey) {
        const nextTutorial = tutorials.find((t) => t.key === actionBtn.nextTutorialKey);
        // check next tutorial completed or not
        if (
          (completedTutorials || []).findIndex((completedTutorial) => completedTutorial.key === nextTutorial?.key) ===
          -1
        ) {
          // if not completed -> switch to next tutorial
          tutorial$.next(nextTutorial);
        }
      }
      setCompleteAction(undefined);
      if (actionBtn.url) navigate(actionBtn.url);
    },
    [completedTutorials, navigate, tutorial$],
  );

  const onSkipClick = useCallback(() => {
    window.sessionStorage.setItem(`skip-tutorial`, 'true');
    setSkipTutorial(true);
  }, []);

  const onTutorialStarted = useCallback(
    (tutorial: Tutorial) => {
      if (tutorial.steps.length) {
        if (tutorial.steps[0].url) navigate(tutorial.steps[0].url);
      }
      tutorial$.next(tutorial);
      setSkipTutorial(false);
      window.sessionStorage.removeItem(`skip-tutorial`);
    },
    [navigate, tutorial$],
  );

  return (
    <>
      {children}
      {!landingCompleted && showHint ? (
        <TutorialLandingDialog
          open
          onSkip={onSkipClick}
          onLandingCompleted={() => {
            onTutorialFinished({ key: 'landing-page', name: 'Landing Page', steps: [] }, false);
            navigate(Uris.Pages.WalletSelector.Index);
            landingCompleted$.next(true);
            tutorial$.next(tutorials[0]);
          }}
        />
      ) : null}
      {component && showHint ? <TutorialHighlight component={component} /> : null}
      {component && tutorialStep && showHint ? (
        <TutorialHint
          component={component}
          tutorialStep={tutorialStep}
          onSkipClick={onSkipClick}
          onNextClick={onNextClick}
        />
      ) : null}
      {completeAction ? (
        <TutorialCompleteDialog
          open
          completeAction={completeAction}
          onActionBtnClick={onActionBtnClick}
          onSkip={onSkipClick}
        />
      ) : null}
      {!completeAction && rewardOpened ? <TutorialRewardDialog open onClose={() => setRewardOpened(false)} /> : null}
      <TutorialGetStarted completedTutorials={completedTutorials || []} onTutorialStarted={onTutorialStarted} />
    </>
  );
};
