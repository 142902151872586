import { AppsOutlined, CheckOutlined, CloseOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IntegrationAuth, IntegrationAuthProvider } from 'common/types/IntegrationAuth';
import { colorNeutralVariant20, colorPrimary40, colorSurface1 } from 'common/params';

import { AttributionSource } from 'common/types/AttributionSource';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { GAPropertiesSelect } from './GAPropertiesSelect';
import { IOSSwitch } from 'components/switch/IOSSwitch';
import { Spinner } from 'components/common/Spinner';
import classes from './CreateAttributionSourceDialog.module.scss';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

enum CreateAttributionSourceStep {
  SELECT_PROPERTIES = 'selectProperties',
  CREATE_SUCCESS = 'createSuccess',
}

interface CreateAttributionSourceDialogProps extends DialogProps {
  integrationAuth: IntegrationAuth;
  attributionSources: AttributionSource[];
  onDialogClose?: (refresh?: boolean) => void;
}

export const CreateAttributionSourceDialog: FC<CreateAttributionSourceDialogProps> = ({
  integrationAuth,
  attributionSources,
  onDialogClose,
  ...props
}) => {
  const { showMessage } = useMessage();
  const [selectedPropertyIds, setSelectedPropertyIds] = useState<string[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [step, setStep] = useState<CreateAttributionSourceStep>(CreateAttributionSourceStep.SELECT_PROPERTIES);
  const [creating, setCreating] = useState<boolean>(false);
  const [successLength, setSuccessLength] = useState<number>(0);

  const {
    loading,
    error,
    value: accounts,
  } = useAsync(async () => {
    return (await ClientAPI.getGAAccounts(integrationAuth.id)).data;
  }, []);

  useEffect(() => {
    error && showMessage(error?.message || 'Unknown Error', 'error');
  }, [error, showMessage]);

  const onPropertyIdsChanged = useCallback((propertyIds: string[]) => {
    setSelectedPropertyIds(propertyIds);
  }, []);

  const createAttributionSource = useCallback(async () => {
    setCreating(true);
    await ClientAPI.createAttributionSources(
      IntegrationAuthProvider.GA,
      integrationAuth.id,
      selectedPropertyIds,
      disabled,
    )
      .then(({ data, message }) => {
        if (data?.length) {
          setSuccessLength(data?.length);
          setStep(CreateAttributionSourceStep.CREATE_SUCCESS);
          return;
        }
        showMessage(`Create GA attribution source failed, ${message}`);
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
      })
      .finally(() => {
        setCreating(false);
      });
  }, [integrationAuth.id, selectedPropertyIds, disabled, showMessage]);

  const content = useMemo(() => {
    switch (step) {
      case CreateAttributionSourceStep.SELECT_PROPERTIES:
        return (
          <>
            <Stack className={classes.content}>
              <Stack alignItems='center' spacing={2}>
                <CommonChip>
                  <AppsOutlined fontSize='large' />
                </CommonChip>
                <Stack spacing={1}>
                  <Typography variant='h6' textAlign='center'>
                    Choose Google Analytics Properties & Apps to Sync
                  </Typography>
                  <Typography variant='body1' textAlign='center' color={colorNeutralVariant20}>
                    Where can I find my config info?
                  </Typography>
                </Stack>
                {loading ? (
                  <Stack alignItems='center'>
                    <Spinner />
                  </Stack>
                ) : (
                  <Stack spacing={3} className={classes.input}>
                    <Stack className={classes.select}>
                      <GAPropertiesSelect
                        accounts={accounts || []}
                        attributionSources={attributionSources}
                        selectedPropertyIds={selectedPropertyIds}
                        onPropertyIdsChange={onPropertyIdsChanged}
                      />
                    </Stack>
                    <Stack className={classes.checkbox} direction='row' alignItems='center' spacing={2}>
                      <IOSSwitch checked={!disabled} onChange={(e) => setDisabled(!e.target.checked)} />
                      <Stack spacing={1}>
                        <Typography variant='body1'>Automated Google Analytics Updates with Our App.</Typography>
                        <Typography variant='label1' color={colorNeutralVariant20}>
                          Your data will be synced daily at UTC+8, 00:00.
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
              <Button
                variant='contained'
                disabled={!selectedPropertyIds.length || creating}
                startIcon={creating ? <Spinner size={24} /> : null}
                onClick={() => {
                  createAttributionSource();
                }}
              >
                Next
              </Button>
            </Stack>
          </>
        );
      case CreateAttributionSourceStep.CREATE_SUCCESS:
        return (
          <>
            <Stack className={classes.content}>
              <Stack alignItems='center' spacing={2}>
                <CommonChip>
                  <CheckOutlined fontSize='large' />
                </CommonChip>
                <Stack spacing={1}>
                  <Typography variant='h6' textAlign='center'>
                    {successLength} properties have been added.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction='row' spacing={1} className={classes.actions} justifyContent='center'>
              <Button
                variant='contained'
                onClick={() => {
                  onDialogClose?.(step === CreateAttributionSourceStep.CREATE_SUCCESS);
                }}
              >
                Back to list
              </Button>
            </Stack>
          </>
        );
    }
  }, [
    loading,
    creating,
    step,
    selectedPropertyIds,
    accounts,
    attributionSources,
    successLength,
    disabled,
    createAttributionSource,
    onPropertyIdsChanged,
    onDialogClose,
  ]);

  return (
    <Dialog
      className={classes.dialog}
      onClose={(evt, reason) => {
        reason !== 'backdropClick' && onDialogClose?.(step === CreateAttributionSourceStep.CREATE_SUCCESS);
      }}
      PaperProps={{ sx: { backgroundColor: colorSurface1, borderRadius: '28px' } }}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Stack direction='row-reverse' justifyContent='space-between' alignItems='center'>
          <IconButton onClick={() => onDialogClose?.(step === CreateAttributionSourceStep.CREATE_SUCCESS)}>
            <CloseOutlined style={{ color: colorPrimary40 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      {content}
    </Dialog>
  );
};
