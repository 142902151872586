import { Collapse, Divider, Slider, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FollowersFilter, getValueFilterString } from 'common/types/Extension/InfluencerFilter';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';

import { MetricsRange } from 'common/types/Extension/TwitterUserSearchResult';
import { MinMaxValueInput } from 'components/input/MinMaxValueInput';
import classes from './FollowersFilter.module.scss';
import { numberToAbbreviation } from 'common/utils';

interface FollowersFilterCollapseProps {
  data?: FollowersFilter;
  metricsRange?: MetricsRange;
  onChange?: (data?: FollowersFilter) => void;
}

const marks = [
  {
    value: 0,
    label: '0',
    followers: 0,
  },
  {
    value: 1,
    label: '10K',
    followers: 10000,
  },
  {
    value: 2,
    label: '100K',
    followers: 100000,
  },
  {
    value: 3,
    label: '500K',
    followers: 500000,
  },
  {
    value: 4,
    label: '1M',
    followers: 1000000,
  },
  {
    value: 5,
    label: '∞',
    followers: Number.MAX_VALUE,
  },
];

const isInt = (n: number) => {
  return n % 1 === 0;
};

const getSliderValue = (value: number) => {
  for (let i = marks.length - 2; i >= 0; i--) {
    const mark = marks[i];
    const prevMark = marks[i + 1];
    if (value >= mark.followers) {
      if (mark.followers > 1000000) return prevMark.value;
      if (mark.followers === value) return mark.value;
      return (
        mark.value + ((value - mark.value) / (prevMark.followers - mark.followers)) * (prevMark.value - mark.value)
      );
    }
  }
  return marks[0].value;
};

export const FollowersFilterCollapse: FC<FollowersFilterCollapseProps> = ({ data, metricsRange, onChange }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [sliderValues, setSliderValues] = useState<number[]>([marks[0].value, marks[marks.length - 1].value]);

  useEffect(() => {
    const value1 = data?.min === undefined ? marks[0].value : getSliderValue(data.min);
    const value2 = data?.max === undefined ? marks[marks.length - 1].value : getSliderValue(data.max);
    setSliderValues([value1, value2]);
  }, [data]);

  return (
    <Stack className={classes.root}>
      <Stack
        direction='row'
        className={classes.title}
        justifyContent='space-between'
        onClick={() => setOpen((old) => !old)}
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          <Typography>Followers</Typography>
          <Typography className={classes.value} variant='label1'>
            {getValueFilterString(data, numberToAbbreviation)}
          </Typography>
        </Stack>
        {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      </Stack>
      <Collapse in={open} timeout='auto'>
        <Stack spacing={3}>
          <Divider className={classes.divider} />
          <Stack spacing={3} className={classes.input}>
            <Stack>
              <Stack direction='row' justifyContent='space-around' alignItems='center' className={classes.sliderBar}>
                <Stack className={classes.levelText}>
                  <Typography variant='label2'>Nano</Typography>
                  <Typography variant='label2'>Influencers</Typography>
                </Stack>
                <Stack className={classes.levelText}>
                  <Typography variant='label2'>Micro</Typography>
                  <Typography variant='label2'>Influencers</Typography>
                </Stack>
                <Stack className={classes.levelText}>
                  <Typography variant='label2'>Macro</Typography>
                  <Typography variant='label2'>Influencers</Typography>
                </Stack>
                <Stack className={classes.levelText}>
                  <Typography variant='label2'>Mega</Typography>
                  <Typography variant='label2'>Influencers</Typography>
                </Stack>
                <Stack className={classes.levelText}>
                  <Typography variant='label2'>Celebrity</Typography>
                </Stack>
              </Stack>
              <Slider
                value={sliderValues}
                step={1}
                valueLabelDisplay='off'
                marks={marks}
                min={marks[0].value}
                max={marks[marks.length - 1].value}
                onChange={(_, newValue: number | number[]) => {
                  if (typeof newValue !== 'object') return;
                  let min = !isInt(newValue[0])
                    ? data?.min
                    : newValue[0] === marks[0].value
                    ? undefined
                    : newValue[0] === marks[marks.length - 1].value
                    ? marks[marks.length - 2].followers
                    : marks[newValue[0]].followers;
                  const max = !isInt(newValue[1])
                    ? data?.max
                    : newValue[1] === marks[marks.length - 1].value
                    ? undefined
                    : marks[newValue[1]].followers;
                  if (typeof min === 'number' && typeof max === 'number' && min > max) min = max;
                  if (min === undefined && max === undefined) {
                    onChange?.(undefined);
                    return;
                  }
                  onChange?.({ ...data, min: min, max: max, top: undefined });
                }}
              />
            </Stack>
            <Divider />
            <MinMaxValueInput
              min={data?.min}
              max={data?.max}
              minLimit={metricsRange?.followers_min}
              maxLimit={metricsRange?.followers_max}
              onChange={(min, max) => {
                let finalMin = min;
                if (typeof min === 'number' && typeof max === 'number' && min > max) finalMin = max;
                if (finalMin === undefined && max === undefined) {
                  onChange?.(undefined);
                  return;
                }
                onChange?.({ ...data, min: finalMin, max: max, top: undefined });
              }}
            />
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};
