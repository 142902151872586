import { Audience, DefaultAudienceData } from 'common/types/Audience';
import { Box, Breadcrumbs, Button, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { AudienceApproachEdit } from 'pages/AdsAudience/Audience/AudienceApproachEdit';
import { AudienceInfoEdit } from 'pages/AdsAudience/Audience/AudienceInfoEdit';
import ClientAPI from 'common/ClientAPI';
import { CommonChip } from 'components/chip/CommonChip';
import { CommonDialog } from 'components/dialog/CommonDialog';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { FindReplaceOutlined } from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Uris } from 'Uris';
import classes from './CreateAudience.module.scss';
import { cloneDeep } from 'lodash';
import { useLeavePageConfirm } from 'common/hooks/useLeavePageConfirm';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';

const tabs: string[] = ['About', 'Approach'];

enum CreateAudienceStep {
  BasicInfo,
  ChooseSource,
}

export const CreateAudience: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showMessage } = useMessage();
  const { track } = useTracking();
  // check whether there is any audience data in state
  // if yes -> use it. if no -> use default audience data
  const [audience, setAudience] = useState<Audience>(
    state?.audience ? state?.audience : cloneDeep(DefaultAudienceData),
  );
  const [tab, setTab] = useState<CreateAudienceStep>(CreateAudienceStep.BasicInfo);
  const [successDialogOpened, setSuccessDialogOpened] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);

  /** Window close with edited content not saved */
  useLeavePageConfirm(!successDialogOpened); // when success, disable leave page confirm

  const onCreateBtnClicked = useCallback(
    async (audience: Audience) => {
      setCreating(true);
      await ClientAPI.createAudience(audience)
        .then(({ status, message, data }) => {
          if (status !== 'success' || !data) {
            showMessage(`Create audience failed: ${message}`);
            return;
          }
          setSuccessDialogOpened(true);
          track('form_submit', {
            sub_event: 'audience_created',
            custom_props: {
              audience_id: data.id,
              audience_name: audience.name,
              selected_cohort: audience.cohorts.map((cohort) => cohort.id),
              audience_approch: audience.target,
            },
          });
        })
        .catch((error) => {
          showMessage(error instanceof Error ? error.message : 'Unknown Error', 'error');
        })
        .finally(() => {
          setCreating(false);
        });
    },
    [showMessage, track],
  );

  const onAudienceEdit = useCallback((audience: Audience) => {
    setAudience(audience);
  }, []);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  }, []);

  const onViewAudiencesBtnClicked = useCallback(() => {
    setSuccessDialogOpened(false);
    navigate(Uris.Pages.AdsAudience.Index);
  }, [navigate]);

  const mainContent = useMemo(() => {
    switch (tab) {
      case 0: // About
        return (
          <AudienceInfoEdit
            audience={audience}
            onAudienceInfoEdit={onAudienceEdit}
            onNext={() => {
              setTab((t) => t + 1);
            }}
            onBack={() => {
              navigate(Uris.Pages.AdsAudience.Index);
            }}
          />
        );
      case 1: // Approach
        return (
          <AudienceApproachEdit
            audience={audience}
            creating={creating}
            onAudienceApproachEdit={onAudienceEdit}
            onBack={() => setTab((t) => t - 1)}
            onCreate={() => onCreateBtnClicked(audience)}
          />
        );
      default:
        return <></>;
    }
  }, [tab, audience, creating, onAudienceEdit, onCreateBtnClicked, navigate]);

  return (
    <Box className={classes.root}>
      <Breadcrumbs separator={<NavigateNextIcon />} aria-label='breadcrumb'>
        <Link
          variant='subtitle2'
          underline='none'
          className={classes.link}
          onClick={() => navigate(Uris.Pages.AdsAudience.Index)}
        >
          Ads Audience
        </Link>
        <Typography variant='subtitle2'>Create</Typography>
      </Breadcrumbs>
      <Stack direction='column' spacing={4} className={classes.form}>
        <Tabs value={tab} onChange={handleTabChange} centered className={classes.tabs}>
          {tabs.map((t, idx) => (
            <Tab
              key={t}
              className={classes.tab}
              disabled={tab < idx}
              label={
                <Stack direction='row' alignItems='center' spacing={1}>
                  <FiberManualRecordIcon />
                  <Typography variant='subtitle2'>{t}</Typography>
                </Stack>
              }
            />
          ))}
        </Tabs>
        {mainContent}
      </Stack>
      {/* create success dialog */}
      <CommonDialog
        open={successDialogOpened}
        mainContent={
          <Stack spacing={2} alignItems='center' className={classes.dialog}>
            <CommonChip>
              <FindReplaceOutlined fontSize='large' />
            </CommonChip>
            <Typography variant='h6' className={classes.title}>
              Finding Audience
            </Typography>
            <Stack>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>•</Typography>
                <Typography variant='body1'>
                  Your selected wallet addresses is syncing with their Twitter accounts.
                </Typography>
              </Stack>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>•</Typography>
                <Typography variant='body1'>The syncing may take up to 1~3 hours.</Typography>
              </Stack>
              <Stack direction='row' spacing={1}>
                <Typography variant='body1'>•</Typography>
                <Typography variant='body1'>
                  Once completed, you will see 'Ready' status on the Audience main list.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        }
        footer={
          <Button variant='contained' onClick={onViewAudiencesBtnClicked}>
            Back to list
          </Button>
        }
        onDialogClose={onViewAudiencesBtnClicked}
      />
    </Box>
  );
};
